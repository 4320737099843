// Packages
import React, { useEffect, useState } from "react";
import _, { toString } from "lodash";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';

// Layouts
import MainLayout from "../../layouts/MainLayout";

// Utils
import { IconSpin, IconGiveLoveHand } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";

// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_SUPPORTER } from "../../core/Constants/API_URL";

// Components
import { Button } from "../../components";
import { shallowEqual, useSelector } from "react-redux";
import IconChevronLeft from "../../utils/icons/_chevronLeft";
import IconChevronRight from "../../utils/icons/_chevronRight";
import IconBack from "../../utils/icons/_back";

const SupporterListPage = () => {
    const user = useSelector(state => state.user, shallowEqual);
    const navigate = useNavigate();

    const isProgress = {
        icon: "animate-spin",
        text: "ml-8",
        wrapper: "flex items-center"
    }

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [recordCount, setRecordCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const fetchData = async (page) => {
        if (user.personType !== 2) {
            navigate('/');
            return;
        }

        const responseData = await query.requestAsync({
            request: {
                method: "get",
                url: API_URL_SUPPORTER.replace(":page", page)
            }
        });

        setLoading(false);
        setPage(page);
        if (!responseData.isError && responseData.length > 0) {
            setPageCount(responseData[0].pageCount);
            setRecordCount(responseData[0].recordCount);
            setDataSource(responseData);
        }
    }

    useEffect(() => {
        fetchData(page).then(p => p);
    }, [])

    const firstPage = async () => {
        await fetchData(1);
    }

    const lastPage = async () => {
        await fetchData(pageCount);
    }

    const prev = async () => {
        await fetchData(page - 1);
    }

    const next = async () => {
        await fetchData(page + 1);
    }


    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white sticky -top-20 z-50">
                    <IconGiveLoveHand className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                icon={<IconBack width={12} />}
                                onClick={() => navigate("/")}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800">Destek Sağlayıcılar</h1>
                        </div>
                    </div>

                </div>

                {
                    loading ? <div className="p-16 text-20 bg-white text-center">
                        <IconSpin
                            className={isProgress.icon}
                            width={24}
                        />

                        Yükleniyor...
                    </div> :
                        (
                            recordCount === 0
                                ? <div className="p-16 text-14 bg-white">
                                    Şu anda gelen bir destek sağlayıcı kaydı bulunmamaktadır.
                                </div>
                                :
                                <div className="p-16 text-14 bg-white">
                                    <table className="table-with-text text-13">
                                        <thead className="sticky top-50 z-50">
                                            <tr className="bg-gray-100">
                                                <th width={130} className="text-left px-8 py-8">Tarih</th>
                                                <th width={100} className="text-left px-8 py-8">No</th>
                                                <th width={250}  className="text-left">Yetkili/Kurum</th>
                                                <th width={200} className="text-left">İl/İlçe</th>
                                                <th width={150}  className="text-left">Maddi Destek</th>
                                                <th className="text-left">Açıklama</th>
                                                <th className="text-left">Özet</th>
                                                <th className="text-left pr-8">
                                                    Detay
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>{
                                            _.map(dataSource, (item, index) => {
                                                return (
                                                    <tr key={`item_${index}`} style={{ borderBottom: '1px solid #eee' }}>
                                                        <td data-title="Tarih" className="text-left px-8">
                                                            {Moment(item.cDate).format('DD.MM.YYYY HH:mm')}
                                                        </td>
                                                        <td data-title="No" className="text-left">
                                                            #DS{toString(item.id).padStart(5, '0')}
                                                        </td>
                                                        <td data-title="Yetkili/Kurum" className="text-left">
                                                            {item.name}
                                                            <br />
                                                            <small>{item.corporationName}</small>
                                                        </td>
                                                        <td data-title="İl/İlçe" className="text-left">
                                                            {item.city}/{item.town}
                                                        </td>
                                                        <td data-title="Maddi Destek" className="text-left">
                                                            {item.amount}
                                                        </td>
                                                        <td data-title="Açıklama" className="text-left">
                                                            {item.description}
                                                        </td>
                                                        <td data-title="Özet" className="text-left">
                                                            {item.summary}
                                                        </td>
                                                        <td className="text-center py-4">
                                                            <Button
                                                                icon={<IconChevronRight width={10} />}
                                                                className="ml-auto mr-auto"
                                                                variant="default"
                                                                size="small"
                                                                onClick={() => navigate('/supporter-detail/' + item.id)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>


                                    <div className="mt-5 text-14 bg-white">
                                        Kayıt Sayısı : {(page - 1) * 20}-{(((page - 1) * 20) + 20) > recordCount ? recordCount : ((page - 1) * 20) + 20}/{recordCount}
                                    </div>

                                    <div className="flex justify-between mt-16">
                                        {
                                            page > 1
                                                ? <div className="flex">
                                                    <Button
                                                        isProgress={query.isAjax}
                                                        className="flex-6"
                                                        onClick={() => firstPage()}
                                                        text="<< İlk Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                    <Button
                                                        icon={<IconChevronLeft width={10} />}
                                                        className="flex-6"
                                                        isProgress={query.isAjax}
                                                        onClick={() => prev()}
                                                        text="Önceki Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    /></div>
                                                : <></>
                                        }

                                        {
                                            page < pageCount
                                                ?
                                                <div className="flex ml-auto">
                                                    <Button
                                                        icon={<IconChevronRight width={10} />}
                                                        iconPosition="right"
                                                        isProgress={query.isAjax}
                                                        onClick={() => next()}
                                                        text="Sonraki Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                    <Button
                                                        isProgress={query.isAjax}
                                                        onClick={() => lastPage()}
                                                        text="Son Sayfa >>"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                </div>

                                                : <></>
                                        }
                                    </div>
                                </div>
                        )
                }



            </div>
        </MainLayout>
    );
}

export default SupporterListPage;
