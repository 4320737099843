// Packages
import React, { useEffect, useState } from "react";
import _, { toString } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import Moment from 'moment';
import { shallowEqual, useSelector } from "react-redux";

// Layouts
import MainLayout from "../../layouts/MainLayout";

// Utils
import { IconBarChartGraph, IconGiveLoveHand } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";

// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_SUPPORTER_BY_ID } from "../../core/Constants/API_URL";

// Components
import { Accordion, Button } from "../../components";
import IconBack from "../../utils/icons/_back";

const SupporterDetailPage = () => {
    const user = useSelector(state => state.user, shallowEqual);
    const navigate = useNavigate();
    const { id } = useParams();
    const [dataSource, setDataSource] = useState({});

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    useEffect(() => {
        if (user.personType !== 2) {
            navigate('/');
            return;
        }
        const fetchData = async () => {
            const responseData = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_SUPPORTER_BY_ID.replace(":id", id)
                }
            });

            if (!responseData.isError) {
                setDataSource(responseData);
            }
        }

        fetchData().then(p => p);
    }, [])

    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white  sticky -top-20 z-50">
                    <IconGiveLoveHand className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                className="mt-10 text-16"
                                onClick={() => navigate("/supporters")}
                                icon={<IconBack width={12} />}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800 relative z-1">
                                #DS{toString(dataSource.id).padStart(5, '0')}
                            </h1>
                            <h6 className="text-12 text-gray-800">{Moment(dataSource.cDate).format('DD.MM.YYYY HH:mm')}</h6>
                        </div>
                    </div>
                </div>

                <div className="p-16 text-14 bg-white">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td width={220} className="px-8 py-4"><b>Ad Soyad</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.name}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Kurum/Klinik Adı</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.corporationName}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Telefon</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.gsm}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>İl/İlçe</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.city}/{dataSource.town}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Adres</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.address}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Açıklama</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.description}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Maddi Destek</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.amount}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Sağlayabileceği Destekler</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.summary}</td>
                            </tr>
                        </tbody>
                    </table>



                    {
                        dataSource.supporterVets && dataSource.supporterVets.length > 0 ?
                            <>

                                <h1 className="px-8 py-4 text-14 font-bold mt-20">
                                    Veteriner Hekim / Yardımcı Personel Desteği
                                </h1>
                                <hr />
                                <table cellPadding={4} className="table-with-text">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th width={30}>#</th>
                                            <th width={200}>Ünvanı</th>
                                            <th width={200}>Adı</th>
                                            <th width={120}>Gsm</th>
                                            <th>Açıklama</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(dataSource.supporterVets, (sItem, sIndex) => {
                                                return (
                                                    <tr key={`table_row_${sIndex}`}>
                                                        <td data-title="#">{sIndex + 1}</td>
                                                        <td data-title="Ünvan">
                                                            {sItem.title}
                                                        </td>
                                                        <td data-title="Adı">
                                                            {sItem.name}
                                                        </td>
                                                        <td data-title="Gsm">
                                                            {sItem.gsm}
                                                        </td>
                                                        <td data-title="Açıklama">
                                                            {sItem.description}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </>
                            : <>
                            </>
                    }




                    <div className="flex justify-between mt-16">
                        <Button
                            icon={<IconBack width={12} />}
                            className="flex-6"
                            onClick={() => navigate("/supporters")}
                            text="Listeye Dön"
                            variant="default"
                        />
                    </div>
                </div>
            </div>
        </MainLayout >
    );
}

export default SupporterDetailPage;
