// Packages
import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import _ from "lodash";
// Core
import {API_URL_BASE} from "../../core/Constants/API_URL";


const useQuery = ({
    axiosInstance: _axiosInstance,
    request: _request,
    requestFunctions: _requestFunctions,
    requestOnLoad: _requestOnLoad,
    showErrorToast: _showErrorToast
}) => {
    const [result, setResult] = useState({
        isAjax: false,
        isEmpty: false,
        isError: false
    });

    const requestAsync = useCallback(async ({
        axiosInstance = _axiosInstance,
        request = _request,
        requestFunctions = _requestFunctions
    }) => {
        const _aInstance = axiosInstance || axios.create({baseURL: API_URL_BASE});

        const _req = _.extend(
            {},
            {
                ...request,
                headers: {
                    ...request.headers
                }
            },
            requestFunctions
        );

        setResult({
            isAjax: true,
            isEmpty: false,
            isError: false,
            response: []
        });

        return _aInstance.request(_req)
            .then(result => {
                const response = result.data.isSuccess === undefined
                    ? result.data
                    : result.data.isSuccess
                        ? result.data.data
                        : []

                setResult({
                    isAjax: false,
                    isEmpty: response && response.length === 0,
                    isError: false,
                    response
                });

                return response;
            })
            .catch(error => {
                setResult({
                    error,
                    isAjax: false,
                    isEmpty: true,
                    isError: true
                });

                return {
                    error,
                    isError: true
                };
            })
    }, []);

    useEffect(() => {
        _requestOnLoad && requestAsync({}).then(p => p);
    }, [_requestOnLoad]);

    const clear = useCallback(() => {
        setResult(state => {
            return {
                isAjax: false,
                isEmpty: false,
                isError: false
            }
        })
    }, []);

    return {
        clear,
        isAjax: result.isAjax,
        isEmpty: result.isEmpty,
        isError: result.isError,
        requestAsync,
        response: result.response,
        showErrorToastMessage: true
    };
}

useQuery.propTypes = {
    axiosInstance: PropTypes.any,
    request: PropTypes.shape({
        data: PropTypes.any,
        headers: PropTypes.any,
        method: PropTypes.oneOf(["get", "post"]),
        url: PropTypes.string,
    }),
    requestFunctions: PropTypes.object,
    requestOnLoad: PropTypes.bool,
    showErrorToast: PropTypes.bool
}

export default useQuery;
