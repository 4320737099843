// Packages
import React, { useCallback, useEffect } from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
// Layouts
import { MainLayout } from "../../layouts";
// Utils
import { IconLifeGuard, IconNew, IconTrash } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";
// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_CATEGORIES, API_URL_CITIES, API_URL_CREATE_SUPPORT, API_URL_TOWNS_BY_CITY_ID } from "../../core/Constants/API_URL";
import { VALIDATION_SCHEMA_GET_SUPPORT } from "../../core/Constants/VALIDATION_SCHEMA";
// Components
import { Accordion, Button, Checkbox, Input, Select } from "../../components";
// Styles
import styles from "./styles";
import IconBack from "../../utils/icons/_back";


const GetSupportPage = () => {
    const navigate = useNavigate();

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const formik = useFormik({
        initialValues: {
            _cities: [],
            _towns: [],
            categories: [],
            idCity: 0,
            idTown: 0,
            address: "",
            location: "",
            importanceLevel: 3,
            description: "",
            isValid: false,
            status: 1
        },
        onSubmit: async data => {
            let _data = {
                supportAddress: {
                    idCity: data.idCity,
                    idTown: data.idTown,
                    address: data.address,
                    location: data.location
                },
                importanceLevel: data.importanceLevel,
                description: data.description,
                isValid: data.isValid,
                status: data.status,
                items: []
            }

            _.forEach(data.categories, mainItem => {
                _.forEach(mainItem.subItem, subItem => {
                    if (subItem.name && subItem.quantity !== "" && subItem.quantity > 0) {
                        _data.items.push({
                            idCategory: mainItem.idCategory,
                            ...subItem
                        })
                    }
                })
            });


            if (_data.items.length === 0 && _data.description === "") {
                alert("En az 1 ihtiyaç kaydı veya açıklama metni giriniz!")
            }
            else if (_data.items.filter(t => t.name === "" || t.name === null).length > 0) {
                alert("İhtiyacın ne olduğunu belirtiniz!")
            }
            else if (_data.items.filter(t => isNaN(parseFloat(t.quantity))).length > 0) {
                alert("İhtiyaç sayı/miktar alanlarını doğru formatta giriniz!")
            }
            else {
                const result = await query.requestAsync({
                    request: {
                        data: _data,
                        url: API_URL_CREATE_SUPPORT
                    }
                });
                //
                if (!result.isError) {
                    alert(`DI000${result.idSupportRequest} no'lu talebiniz Afet.Vet e ulaşmıştır. Sizinle en kısa sürede iletişim sağlanacaktır!`);
                    navigate("/");
                }
            }
        },
        validationSchema: VALIDATION_SCHEMA_GET_SUPPORT()
    })

    useEffect(() => {
        const fetchData = async () => {
            // if (navigator.geolocation) {
            //     navigator.geolocation.getCurrentPosition(async position => {
            //         await formik.setFieldValue("location", `${position.coords.latitude},${position.coords.longitude}`);
            //     });
            // }

            const cities = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_CITIES
                }
            });

            const categories = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_CATEGORIES
                }
            });

            if (!cities.isError && cities.length > 0) {
                await formik.setFieldValue("_cities", _.map(cities, item => {
                    return {
                        value: item.idCity,
                        label: item.name
                    }
                }));
                await formik.setFieldValue("idCity", cities[0].idCity);
            }

            if (!categories.isError) {
                await formik.setFieldValue("categories", _.map(_.orderBy(categories, ["sortNo"]), item => {
                    return {
                        ...item,
                        subItem: (
                            item.idCategory === 1
                                ? (
                                    [
                                        {
                                            name: "Veteriner Hekim",
                                            quantity: 0,
                                            unit: item.units.split(",")[0]
                                        },
                                        {
                                            name: "Veteriner Teknikeri / Teknisyeni",
                                            quantity: 0,
                                            unit: item.units.split(",")[0]
                                        },
                                        {
                                            name: "Yardımcı Personel",
                                            quantity: 0,
                                            unit: item.units.split(",")[0]
                                        }
                                    ]
                                )
                                : (
                                    [
                                        {
                                            name: "",
                                            quantity: 0,
                                            unit: item.units.split(",")[0]
                                        }
                                    ]
                                )
                        )
                    }
                }));
            }
        }

        fetchData().then(p => p);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const towns = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_TOWNS_BY_CITY_ID.replace(":id", formik.values.idCity)
                }
            });

            if (!towns.isError && towns.length > 0) {
                await formik.setFieldValue("_towns", _.map(towns, item => {
                    return {
                        value: item.idTown,
                        label: item.name
                    }
                }));

                await formik.setFieldValue("idTown", towns[0].idTown);
            }
        }

        formik.values.idCity && fetchData().then(p => p);
    }, [formik.values.idCity]);

    const onChangeAccordionElement = useCallback(async (value, name, idCategory, itemIndex) => {
        if (name === "quantity" && parseFloat(value) < 0) {
            return;
        }

        // if (name === "quantity" && isNaN(parseFloat(value))) {
        //     return;
        // }


        if (name === "quantity" && idCategory === 1 && value.indexOf('.') >= 0) {
            return;
        }


        let datas = formik.values.categories;
        const index = _.findIndex(datas, o => o.idCategory === idCategory);

        datas[index].subItem[itemIndex] = {
            ...datas[index].subItem[itemIndex],
            [name]: name === "quantity" ? (value ? parseFloat(value) : value) : value
        };

        await formik.setFieldValue("categories", datas);

    }, [formik.values.categories]);

    const onAddCategoryItem = useCallback(async (idCategory) => {
        let datas = formik.values.categories;
        const index = _.findIndex(datas, o => o.idCategory === idCategory);
        const item = _.find(datas, o => o.idCategory === idCategory);

        datas[index].subItem.push({
            name: "",
            quantity: 0,
            unit: item.units.split(",")[0]
        });

        await formik.setFieldValue("categories", datas);
    }, [formik.values.categories]);

    const onRemoveCategoryItem = useCallback(async (idCategory, itemIndex) => {
        let datas = formik.values.categories;
        const index = _.findIndex(datas, o => o.idCategory === idCategory);

        datas[index].subItem.splice(itemIndex, 1);

        await formik.setFieldValue("categories", datas);
    }, [formik.values.categories]);

    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white  sticky -top-20 z-50">
                    <IconLifeGuard className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                className="text-16 mt-10"
                                onClick={() => navigate("/")}
                                icon={<IconBack width={12} />}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800">Destek İste</h1>
                            <div className="block text-12 text-gray-500">İhtiyaç duyduğunuz hizmet / ürün ve ekipmanları ekleyiniz.</div>
                        </div>
                    </div>
                </div>

                <div className="p-16 bg-white">
                    <div className="font-bold text-14">Destek Talep Edilen</div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Select
                                items={formik.values._cities}
                                label="İl"
                                onChange={({ target: { value } }) => formik.setFieldValue("idCity", value)}
                                required
                                value={formik.values.idCity}
                            />
                        </div>

                        <div className={styles.column}>
                            <Select
                                items={formik.values._towns}
                                label="İlçe"
                                onChange={({ target: { value } }) => formik.setFieldValue("idTown", value)}
                                required
                                value={formik.values.idTown}
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Input
                                errorMessage={formik.touched.address && formik.errors.address}
                                label="Adres"
                                multiline
                                onChange={({ target: { value } }) => formik.setFieldValue("address", value)}
                                required
                                rows={3}
                                value={formik.values.address}
                                maxLength="500"
                            />
                        </div>
                    </div>


                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Select
                                items={[
                                    { value: 1, label: "Acil" },
                                    { value: 2, label: "Normal" },
                                ]}
                                label="Öncelik"
                                onChange={({ target: { value } }) => formik.setFieldValue("importanceLevel", value)}
                                value={formik.values.importanceLevel}
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <h1 className="text-14 font-bold">İhtiyaçlar</h1>

                            <Accordion
                                items={_.map(_.filter(formik.values.categories, o => o.categoryType !== 2), (item, index) => {
                                    return {
                                        body: (
                                            <>
                                                <table cellPadding={4} className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className={cn("center", { "hidden": item?.idCategory === 1 })} width={32}></th>
                                                            <th>{item?.description}</th>
                                                            <th width={90}>Sayı</th>
                                                            <th width={90}>Birim</th>
                                                            <th className={cn({ "hidden": item?.idCategory === 1 })} width={32}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            _.map(item.subItem, (sItem, sIndex) => {
                                                                return (
                                                                    <tr key={`table_row_${sIndex}`}>
                                                                        <td className={cn("text-14 font-medium text-center", { "hidden": item?.idCategory === 1 })}>{sIndex + 1}</td>
                                                                        <td>
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeAccordionElement(value, "name", item?.idCategory, sIndex)}
                                                                                placeholder={item?.placeholder}
                                                                                disabled={item?.idCategory === 1}
                                                                                value={sItem.name}
                                                                                maxLength="200"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeAccordionElement(value, "quantity", item?.idCategory, sIndex)}
                                                                                type="number"
                                                                                value={sItem.quantity}
                                                                                min={0}
                                                                                maxLength="10"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                items={_.map(item.units.split(","), (unitItem, unitIndex) => {
                                                                                    return {
                                                                                        label: unitItem,
                                                                                        value: unitItem
                                                                                    }
                                                                                })}
                                                                                onChange={({ target: { value } }) => onChangeAccordionElement(value, "unit", item?.idCategory, sIndex)}
                                                                                value={sItem.unit}
                                                                            />
                                                                        </td>
                                                                        <td className={cn({ "!hidden": item?.idCategory === 1 })}>
                                                                            {
                                                                                sIndex !== 0 && (
                                                                                    <div
                                                                                        className="text-red-500 text-center cursor-pointer"
                                                                                        onClick={() => onRemoveCategoryItem(item.idCategory, sIndex)}
                                                                                    >
                                                                                        <IconTrash width={32} className="text-red-500" />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>

                                                {
                                                    item.idCategory !== 1 && (
                                                        <Button
                                                            className="mt-6"
                                                            icon={<IconNew width={16} />}
                                                            onClick={() => onAddCategoryItem(item.idCategory)}
                                                            text="Satır Ekle"
                                                            variant="light"
                                                        />
                                                    )
                                                }
                                            </>
                                        ),
                                        header: item.name,
                                        ...item
                                    }
                                })
                                }
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Input
                                label="Açıklama"
                                multiline
                                onChange={({ target: { value } }) => formik.setFieldValue("description", value)}
                                rows={4}
                                value={formik.values.description}
                                maxLength="2000"
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Checkbox
                                labelClassName="ml-7 text-13"
                                errorMessage={formik.touched.isValid && formik.errors.isValid}
                                onChange={value => formik.setFieldValue("isValid", value)}
                                checked={formik.values.isValid}
                                label="Girdiğim bilgilerin doğru olduğunu onaylıyorum!"
                            />
                        </div>
                    </div>

                    <Button
                        isProgress={query.isAjax}
                        className="ml-auto"
                        onClick={formik.submitForm}
                        text="Destek Talebini Gönder"
                        variant="primary"
                    />
                </div>
            </div>
        </MainLayout>
    );
}

export default GetSupportPage;
