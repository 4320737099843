// Packages
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
// Components
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
// Core
import { LocaleHelper } from "../core/Helpers";
import LoginOrRegisterModal from "../pages/HomePage/LoginOrRegisterModal";
import { StorageHelper } from "../core/Helpers";


const MainLayout = ({
    children,
    contentClassName
}) => {
    LocaleHelper.setLocale();

    return (
        <div className="h-screen flex flex-col">
            <LoginOrRegisterModal />
            <TopMenu />
            <div className={cn("flex-1 overflow-y-auto py-16", contentClassName)}>{children}</div>
            <Footer />
        </div>
    )
}

MainLayout.propTypes = {
    contentClassName: PropTypes.string
}

export default MainLayout;
