// Packages
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';

// Layouts
import MainLayout from "../../layouts/MainLayout";

// Utils
import { IconBarChartGraph, IconSpin, IconNew, IconTrash } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";

// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_GENERAL_STATUS, API_URL_GENERAL_STATUS_BY_ID } from "../../core/Constants/API_URL";

// Components
import { Button } from "../../components";
import { shallowEqual, useSelector } from "react-redux";
import IconEdit from "../../utils/icons/_edit";
import IconChevronLeft from "../../utils/icons/_chevronLeft";
import IconChevronRight from "../../utils/icons/_chevronRight";
import IconBack from "../../utils/icons/_back";

const GeneralStatusPage = () => {
    const user = useSelector(state => state.user, shallowEqual);
    const navigate = useNavigate();

    const isProgress = {
        icon: "animate-spin",
        text: "ml-8",
        wrapper: "flex items-center"
    }

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [recordCount, setRecordCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const fetchData = async (page) => {
        const responseData = await query.requestAsync({
            request: {
                method: "get",
                url: API_URL_GENERAL_STATUS.replace(":page", page)
            }
        });

        setLoading(false);
        setPage(page);
        if (!responseData.isError && responseData.length > 0) {
            setPageCount(responseData[0].pageCount);
            setRecordCount(responseData[0].recordCount);
            setDataSource(responseData);
        }
    }

    useEffect(() => {
        fetchData(page).then(p => p);
    }, [])


    const firstPage = async () => {
        await fetchData(1);
    }

    const lastPage = async () => {
        await fetchData(pageCount);
    }

    const prev = async () => {
        await fetchData(page - 1);
    }

    const next = async () => {
        await fetchData(page + 1);
    }

    const deleteRecord = useCallback(async (id) => {
        const responseData = await query.requestAsync({
            request: {
                method: "delete",
                url: API_URL_GENERAL_STATUS_BY_ID.replace(":id", id)
            }
        });

        if (!responseData.isError) {
            var ds = dataSource.filter(t => t.id !== id);
            setDataSource(ds);
        }
    }, [dataSource]);

    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white  sticky -top-20 z-50">
                    <IconBarChartGraph className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                icon={<IconBack width={12} />}
                                onClick={() => navigate("/")}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800">Koordinasyon/Dayanışma Ekranı</h1>
                        </div>
                    </div>

                </div>

                {
                    loading ? <div className="p-16 text-20 bg-white text-center">
                        <IconSpin
                            className={isProgress.icon}
                            width={24}
                        />

                        Yükleniyor...
                    </div> :
                        (
                            recordCount === 0
                                ? <div className="p-16 text-14 bg-white">
                                    {
                                        user.personType === 2 ?
                                            <div className="pb-16 text-14 bg-white">
                                                <Button
                                                    className="ml-auto"
                                                    text="Yeni Kayıt"
                                                    variant="primary"
                                                    size="small"
                                                    onClick={() => navigate('/general-status-form/0')}
                                                />
                                            </div> :
                                            <></>
                                    }
                                    Şu anda bekleyen bir destek talebi bulunmamaktadır.
                                </div>
                                :
                                <div className="p-16 text-14 bg-white">
                                    {
                                        user.personType === 2 ?
                                            <div className="pb-16 text-14 bg-white">
                                                <Button
                                                    icon={<IconNew width={16} />}
                                                    className="ml-auto"
                                                    text="Yeni Kayıt"
                                                    variant="primary"
                                                    size="small"
                                                    onClick={() => navigate('/general-status-form/0')}
                                                />
                                            </div> :
                                            <></>
                                    }

                                    {/* <div className="flex pb-16">
                                        <Button variant="dark" text="Tümü" style={{ height: '25px' }} size="small" />
                                        <Button variant="danger" text="Destek Bekleniyor" style={{ height: '25px' }} size="small" />
                                        <Button variant="info" text="Koordine Edildi" style={{ height: '25px' }} size="small" />
                                        <Button variant="success" text="Tamamlandı" style={{ height: '25px' }} size="small" />
                                    </div> */}
                                    <table className="table-with-text text-13">
                                        <thead className="sticky top-50 z-50">
                                            <tr className="bg-gray-100">
                                                <th width={130} className="text-left px-8 py-8">Tarih</th>
                                                <th className="text-left">Başlık</th>
                                                <th className="text-left">Yetkili/Kurum</th>
                                                <th width={200} className="text-left">İl/İlçe</th>
                                                <th className="text-left">İhtiyaç Özeti</th>
                                                <th width={150} className="text-left ">Durum</th>
                                                <th className="text-center">
                                                    {user.personType === 2 ? "İşlemler" : "Detay"}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>{
                                            _.map(dataSource, (item, index) => {
                                                return (
                                                    <tr key={`item_${index}`} style={{ borderBottom: '1px solid #eee' }}>
                                                        <td data-title="Tarih" className="text-left px-8">
                                                            {Moment(item.cDate).format('DD.MM.YYYY HH:mm')}
                                                        </td>
                                                        <td data-title="Başlık" className="text-left">
                                                            {item.title}
                                                        </td>
                                                        <td data-title="Yetkili/Kurum" className="text-left">
                                                            {item.name}
                                                            <br />
                                                            <small>{item.corporationName}</small>
                                                        </td>
                                                        <td data-title="İl" className="text-left">
                                                            {item.city}/ {item.town}
                                                        </td>
                                                        <td data-title="İhtiyaç Özeti" className="text-left">
                                                            {item.summary}
                                                            <br />
                                                            <small>{item.importanceLevel === 1 ? "Acil" : "Normal"}</small>
                                                        </td>
                                                        <td data-title="Durum" className="font-bold text-left">

                                                            {
                                                                item.status === 1 ? <span className="text-danger">Destek Bekleniyor</span> :
                                                                    (
                                                                        item.status === 2 ? <span className="text-info">Koordine Edildi</span> :
                                                                            (item.status === 3) ? <span className="text-success">Tamamlandı</span> :
                                                                                "-"
                                                                    )
                                                            }
                                                        </td>

                                                        <td className="text-center py-4">

                                                            <div className="flex justify-between">
                                                                {
                                                                    user.personType === 2 ?
                                                                        <>
                                                                            <Button
                                                                                icon={<IconEdit width={16} />}
                                                                                className="ml-auto mr-2"
                                                                                variant="primary"
                                                                                size="small"
                                                                                onClick={() => navigate('/general-status-form/' + item.id)}
                                                                            />

                                                                            <Button
                                                                                icon={<IconTrash width={16} />}
                                                                                className="ml-auto mr-2"
                                                                                variant="danger"
                                                                                size="small"
                                                                                onClick={() => { if (window.confirm("Silmek istiyor musunuz?")) { deleteRecord(item.id) } }}
                                                                            >
                                                                            </Button>
                                                                        </> :
                                                                        <></>
                                                                }

                                                                <Button
                                                                    icon={<IconChevronRight width={10} />}
                                                                    className="ml-auto mr-auto"
                                                                    variant="default"
                                                                    size="small"
                                                                    onClick={() => navigate('/general-status-detail/' + item.id)}
                                                                />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>


                                    <div className="mt-5 text-14 bg-white">
                                        Kayıt Sayısı : {(page - 1) * 20}-{(((page - 1) * 20) + 20) > recordCount ? recordCount : ((page - 1) * 20) + 20}/{recordCount}
                                    </div>

                                    <div className="flex justify-between mt-16">
                                        {
                                            page > 1
                                                ? <div className="flex">
                                                    <Button
                                                        isProgress={query.isAjax}
                                                        className="flex-6"
                                                        onClick={() => firstPage()}
                                                        text="<< İlk Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                    <Button
                                                        icon={<IconChevronLeft width={10} />}
                                                        className="flex-6"
                                                        isProgress={query.isAjax}
                                                        onClick={() => prev()}
                                                        text="Önceki Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    /></div>
                                                : <></>
                                        }

                                        {
                                            page < pageCount
                                                ?
                                                <div className="flex ml-auto">
                                                    <Button
                                                        icon={<IconChevronRight width={10} />}
                                                        iconPosition="right"
                                                        isProgress={query.isAjax}
                                                        onClick={() => next()}
                                                        text="Sonraki Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                    <Button
                                                        isProgress={query.isAjax}
                                                        onClick={() => lastPage()}
                                                        text="Son Sayfa >>"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                </div>

                                                : <></>
                                        }
                                    </div>
                                </div>
                        )
                }



            </div>
        </MainLayout>
    );
}

export default GeneralStatusPage;
