// Packages
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
// Components
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
// Core
import {LocaleHelper} from "../core/Helpers";


const ContentLayout = ({
    children,
    contentClassName,
    title
}) => {
    LocaleHelper.setLocale();

    return (
        <div className="h-screen flex flex-col">
            <TopMenu/>
            <div className={cn("flex-1 overflow-y-auto py-16", contentClassName)}>
                <div className="container ">
                    <div className="p-16 bg-white">
                        <h1 className="text-24 font-bold mb-16">{title}</h1>
                        <div className="text-14">{children}</div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

ContentLayout.propTypes = {
    title: PropTypes.string,
    contentClassName: PropTypes.string
}

export default ContentLayout;
