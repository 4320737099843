// Packages
import React from "react";
import axios from "axios";
// Core
import {API_URL_BASE} from "./API_URL";
import {StorageHelper} from "../Helpers";


export const STORAGE_KEY_AUTH_TOKENS = "auth_tokens";

export const SIZE_DESKTOP_MIN = 1025;
export const SIZE_TABLET_MIN = 640;


export const AXIOS_INSTANCE = () => {
    return axios.create({
        baseURL: API_URL_BASE,
        headers: {Authorization: `Bearer ${StorageHelper.getAuthTokens().token}`},
        method: "POST",
    });
};
