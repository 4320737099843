// Packages
import React from "react";


const IconLifeGuard = props => (
    <svg
        viewBox="0 0 272 272"
        {...props}
    >
        <path
            fill="#EFEFEF"
            d="M249.94 77.62a128.228 128.228 0 00-227.313-1.967A128.23 128.23 0 10264 136a128.628 128.628 0 00-14.06-58.38zm-78 116.11a67.847 67.847 0 01-71.78 0 68.408 68.408 0 01-21.84-21.84 67.85 67.85 0 010-71.78 68.41 68.41 0 0121.84-21.84 67.85 67.85 0 0171.78 0 68.408 68.408 0 0121.84 21.84 67.847 67.847 0 010 71.78 68.411 68.411 0 01-21.89 21.84h.05z"
        />
        <path
            fill="#E91D62"
            d="M194.38 22.06l-22.49 56.21a67.85 67.85 0 00-71.78 0L77.62 22.06a128.228 128.228 0 01116.76 0zM194.38 249.94a128.234 128.234 0 01-116.76 0l22.49-56.21a67.847 67.847 0 0071.78 0l22.49 56.21zM68 136a67.539 67.539 0 0010.27 35.89l-56.21 22.49a128.228 128.228 0 010-116.76l56.21 22.49A67.539 67.539 0 0068 136zM264 136a127.432 127.432 0 01-14.06 58.38l-56.21-22.49a67.847 67.847 0 000-71.78l56.21-22.49A127.432 127.432 0 01264 136z"
        />
    </svg>
)

export default IconLifeGuard;
