// Packages
import React from "react";
import { useNavigate } from "react-router-dom";
// Layouts
import MainLayout from "../../layouts/MainLayout";
// Utils
import { IconNavigationTable } from "../../utils/icons";
import { Button } from "../../components";
import IconBack from "../../utils/icons/_back";


const RoadmapPage = () => {
    const navigate = useNavigate();
    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white  sticky -top-20 z-50">
                    <IconNavigationTable className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                className="text-16"
                                onClick={() => navigate("/")}
                                icon={<IconBack width={12} />}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800">Proje Hakkında</h1>
                        </div>
                    </div>
                </div>

                <div className="p-16 text-14 bg-white">
                    <p className="py-16 pl-16">
                        <ol className="list-decimal">
                            <li className="py-5">
                                Destek İste ve Destek Sağla ekranlarından talepler toplanır.
                            </li>
                            <li className="py-5">
                                Destek İste ekranından gelen talepler TVHB tarafından değerlendirilir.
                                <br />
                                Onaylanan destek talepleri “Koordinasyon/Dayanışma Ekranında” yayınlanır.
                                <br />
                                Bu ilanın durum bilgisi <strong>“Destek Bekleniyor”</strong> olarak görünür.
                            </li>
                            <li className="py-5">
                                İlana çıkan ihtiyaç listesi Destek Sağla ekranından Destek Sağlama Talebi gönderen veteriner hekimlere sms ile iletilir.
                                <br />
                                (Liste aynı zamanda TVHB, firma ve şahısların web ve sosyal medya hesaplarından haricen duyurulabilir.)
                            </li>
                            <li className="py-5">
                                İlana destek sağlamak isteyen veteriner hekimlerin talepleri toplanır. TVHB tarafından değerlendirilen taleplere istinaden ihtiyaç bölgesinde görev alacak veteriner hekimler ve malzeme tedariği sağlayacak veteriner hekimler/firmalar/kurumlar belirlenir ve koordinasyon merkezi tarafından koordine edilir.
                            </li>
                            <li className="py-5">
                                Koordinasyon/Dayanışma ekranındaki ilanın durum bilgisi <strong>“Koordine Edildi”</strong> olarak görülür ve ilan detayında;
                                <ul className="list-disc pl-20">
                                    <li>Görevlendirilmiş veteriner hekimler</li>
                                    <li>İhtiyaç duyulan ve tedarik edilen malzeme listesi</li>
                                    <li>Destek veren veteriner hekimler</li>
                                </ul>
                                yayınlanır.
                            </li>
                            <li className="py-5">Görevlendirilmiş veteriner hekimler, <strong>afet.vet</strong> üzerinden kendilerine açılmış bir arayüz aracılığıyla durum ve konum bilgilerini güncelleyerek hareket ederler.</li>
                            <li className="py-5">Görevlendirilmiş veteriner hekimler destek istenilen noktaya varış yaptıklarında ilanın durum bilgisi <strong>“Tamamlandı”</strong> olarak görülür.</li>
                        </ol>
                    </p>
                </div>
            </div>
            <div className="container">
                <hr />
                <div className="py-16 pl-16 relative overflow-hidden bg-white">
                    <h1 className="text-24 font-bold text-gray-800">Projede Yapılacaklar</h1>
                </div>

                <div className="p-16 text-14 bg-white">
                    <p>Afet.vet projesinin ilerleyen sürümlerinde;</p>
                    <ul className="p-16">
                        <li className="p-6">Afet alanlarına doğru yola çıkan ve afet bölgelerinde bulunan veteriner hekimlerin birbirlerinin konumlarını görebileceği, afet alanlarına doğru yola çıkan hekimlerin yol üzerindeki ihtiyaçları görüp bölgeye ulaştırabileceği, </li>
                        <li className="p-6">Afetzede meslektaşlarımız ve öğrenciler için yardımlaşma imkanı sunan geliştirmeler yapılmaya devam edilecektir.</li>
                    </ul>
                </div>
            </div>
        </MainLayout>
    );
}

export default RoadmapPage;
