// Packages
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Moment from 'moment';
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { shallowEqual, useSelector } from "react-redux";

// Layouts
import MainLayout from "../../layouts/MainLayout";

// Utils
import { IconBarChartGraph } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";

// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_GENERAL_STATUS_BY_ID, API_URL_GIVE_SUPPORT, API_URL_GENERAL_STATUS_SUPPORTING_BY_ID } from "../../core/Constants/API_URL";

// Components
import { Accordion, Button, Input } from "../../components";
import IconBack from "../../utils/icons/_back";

const GeneralStatusDetailPage = () => {
    const user = useSelector(state => state.user, shallowEqual);
    const navigate = useNavigate();
    const { id } = useParams();

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const [supportingItems, setSupportingItems] = useState([]);

    const formik = useFormik({
        initialValues: {
            _dataSource: {
                cDate: "",
                title: "",
                name: "",
                corporationName: "",
                gsm: "",
                importanceLevel: "",
                status: "",
                city: "",
                town: "",
                address: "",
                summary: "",
                description: "",
                location: "",
                resultDescription: ""

            },
            _categories: [],
            _appointedVets: [],
            _supportingVets: [],
            items: []
        },
        onSubmit: async data => {
            let _data = {
                items: []
            };

            _.forEach(data.items, mainItem => {
                _data.items.push({
                    ...mainItem
                })
            });

            if (_data.items.filter(t => t.giveQuantity > 0 && t.giveQuantity !== "").length === 0) {
                alert("En az 1 ihtiyaç karşılamanız gerekmektedir");
            }

            else if (_data.items.filter(t => isNaN(parseFloat(t.giveQuantity))).length > 0) {
                alert("Sayı/miktar alanlarını doğru formatta giriniz!")
            }
            else {
                const result = await query.requestAsync({
                    request: {
                        data: _data,
                        url: API_URL_GIVE_SUPPORT
                    }
                })

                if (!result.isError) {
                    alert(`Talebiniz Afet.Vet e ulaşmıştır. Sizinle en kısa sürede iletişim sağlanacaktır!`);
                    navigate("/general-status");
                }
            }
        }
    })

    useEffect(() => {
        const fetchData = async () => {
            const result = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_GENERAL_STATUS_SUPPORTING_BY_ID.replace(":id", id)
                }
            });

            if (!result.isError) {
                let groupedPersons = _.chain(result).groupBy("personName").map((value, key) => ({
                    personName: key,
                    personCorporationName: value[0].personCorporationName,
                    personGsm: value[0].personGsm,
                    personCity: value[0].personCity,
                    personTown: value[0].personTown,
                    values: _.chain(value).groupBy("category").map((catValue, catKey) => ({
                        category: catKey,
                        subItems: catValue
                    })).value()
                })).value();

                setSupportingItems(groupedPersons);
            }
        }

        fetchData().then(p => p);
    }, [])


    useEffect(() => {
        const fetchData = async () => {
            const responseData = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_GENERAL_STATUS_BY_ID.replace(":id", id)
                }
            });
            console.log(responseData);
            const cats = [];
            _.forEach(responseData.items, item => {
                if (cats.indexOf(item.category) < 0) {
                    if (responseData.status !== 1 && responseData.appointedVets.length > 0 && item.idCategory === 1) {

                    }
                    else {
                        cats.push(item.category)
                    }
                }
            });

            if (responseData.appointedVets) {
                await formik.setFieldValue("_appointedVets", responseData.appointedVets);
            }

            if (responseData.supportingVets) {
                await formik.setFieldValue("_supportingVets", responseData.supportingVets);
            }

            await formik.setFieldValue("items", responseData.items);
            await formik.setFieldValue("_categories", cats);
            await formik.setFieldValue("_dataSource", { ...responseData })
        }

        fetchData().then(p => p);
    }, [])

    const onChangeGiveQuantity = useCallback(async (value, id) => {
        if (parseFloat(value) < 0) {
            return;
        }

        let datas = formik.values.items;
        let itemData = datas.find(t => t.id === id);

        if (itemData.category.indexOf('Hekim') >= 0 && value.indexOf('.') >= 0) {
            return;
        }

        let index = datas.findIndex(t => t.id === id);
        itemData.giveQuantity = value ? parseFloat(value) : value;
        datas[index] = itemData;

        await formik.setFieldValue("items", datas);

    }, [formik.values.items]);


    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white  sticky -top-20 z-50">
                    <IconBarChartGraph className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                className="mt-10 text-16"
                                onClick={() => navigate("/general-status")}
                                icon={<IconBack width={12} />}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800 relative z-1">
                                {formik.values._dataSource.title}
                            </h1>
                            <h6 className="text-12 text-gray-800">{Moment(formik.values._dataSource.cDate).format('DD.MM.YYYY HH:mm')}</h6>
                        </div>
                    </div>
                </div>

                <div className="p-16 text-14 bg-white">
                    <div className="flex flex-col md:flex-row">
                        <div className="flex-1">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td width={150} className="px-8 py-4"><b>Kurum/Klinik Adı</b></td>
                                        <td width={10} className="px-8 py-4">:</td>
                                        <td className="px-8 py-4">{formik.values._dataSource.corporationName}</td>
                                    </tr>
                                    <tr>
                                        <td width={150} className="px-8 py-4"><b>Yetkili Kişi</b></td>
                                        <td width={10} className="px-8 py-4">:</td>
                                        <td className="px-8 py-4">{formik.values._dataSource.name}</td>
                                    </tr>
                                    <tr>
                                        <td width={150} className="px-8 py-4"><b>Yetkili Telefon</b></td>
                                        <td width={10} className="px-8 py-4">:</td>
                                        <td className="px-8 py-4">{formik.values._dataSource.gsm}</td>
                                    </tr>
                                    <tr>
                                        <td width={150} className="px-8 py-4"><b>İl/İlçe</b></td>
                                        <td width={10} className="px-8 py-4">:</td>
                                        <td className="px-8 py-4">{formik.values._dataSource.city}/{formik.values._dataSource.town}</td>
                                    </tr>
                                    <tr>
                                        <td width={150} className="px-8 py-4"><b>Adres</b></td>
                                        <td width={10} className="px-8 py-4">:</td>
                                        <td className="px-8 py-4">{formik.values._dataSource.address}</td>
                                    </tr>
                                    <tr>
                                        <td width={150} className="px-8 py-4"><b>Açıklama</b></td>
                                        <td width={10} className="px-8 py-4">:</td>
                                        <td className="px-8 py-4">{formik.values._dataSource.description}</td>
                                    </tr>
                                    <tr>
                                        <td width={150} className="px-8 py-4"><b>Durumu</b></td>
                                        <td width={10} className="px-8 py-4">:</td>
                                        <td className="px-8 py-4 font-bold">
                                            {
                                                formik.values._dataSource.status === 1
                                                    ? <span className="text-danger">Destek Bekleniyor</span>
                                                    :
                                                    (
                                                        formik.values._dataSource.status === 2
                                                            ? <span className="text-info">Koordine Edildi</span>
                                                            : (formik.values._dataSource.status === 3 ? <span className="text-success">Tamamlandı</span> : "-")
                                                    )
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="flex-1">
                            {
                                formik.values._dataSource.location
                                    ?
                                    <LoadScript googleMapsApiKey="AIzaSyBxtGNu4VE5V79X4-rfhrorqZkTG5hXsvU">
                                        <GoogleMap
                                            zoom={15}
                                            center={
                                                {
                                                    lat: parseFloat(formik.values._dataSource.location.split(',')[0]),
                                                    lng: parseFloat(formik.values._dataSource.location.split(',')[1]),
                                                }}
                                            mapContainerStyle={{ width: "100%", height: "250px" }}
                                        >
                                            <Marker
                                                position={{
                                                    lat: parseFloat(formik.values._dataSource.location.split(',')[0]),
                                                    lng: parseFloat(formik.values._dataSource.location.split(',')[1]),
                                                }}
                                                title={formik.values._dataSource.address}
                                            ></Marker>

                                        </GoogleMap>
                                    </LoadScript>
                                    : <></>
                            }
                        </div>
                    </div>

                    {
                        formik.values._dataSource.status !== 1 ?
                            formik.values._appointedVets && formik.values._appointedVets.length > 0
                                ?
                                <>
                                    <h1 className="px-8 py-10 text-14  mt-20 font-bold bg-gray-200">Görevlendirilmiş Veteriner Hekimler</h1>
                                    <table className="table-with-text border-1">
                                        <thead className="bg-gray-100 text-left">
                                            <tr>
                                                <th className="px-8 py-4" width={30}>#</th>
                                                <th className="px-8 py-4" width={300}>Adı</th>
                                                <th className="px-8 py-4" width={200}>Gsm</th>
                                                <th className="px-8 py-4">Açıklama</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(formik.values._appointedVets, (item, index) => {
                                                    return (
                                                        <tr key={`tr_av_${index + 1}`}>
                                                            <td data-title="#" className="px-8 py-4">{index + 1}</td>
                                                            <td data-title="Adı" className="px-8 py-4">{item.name}<br /><small>{item.corporationName}</small></td>
                                                            <td data-title="Gsm" className="px-8 py-4">{item.gsm}</td>
                                                            <td data-title="Açıklama" className="px-8 py-4">{item.description}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </>
                                :
                                <></>
                            : <></>
                    }


                    {
                        formik.values._categories.length > 0 ?
                            <>

                                <h1 className="px-8 py-4 text-14 font-bold mt-20">
                                    İhtiyaç Listeleri
                                </h1>
                                <hr />

                                <Accordion
                                    items={_.map(formik.values._categories, (item, _index) => {
                                        return {
                                            body: (
                                                <>
                                                    <table cellPadding={4} className="table-with-text">
                                                        <thead>
                                                            <tr className="bg-gray-100">
                                                                <th width={30}>#</th>
                                                                <th>İhtiyaç</th>
                                                                <th width={120}>{item.indexOf("Hekim") >= 0 ? "Sayı" : "İhtiyaç Miktarı"}</th>

                                                                {
                                                                    user.personType === 2 && formik.values._dataSource.status === 1
                                                                        ? <></>
                                                                        :
                                                                        (
                                                                            formik.values._dataSource.status === 1 ?
                                                                                <th width={180}>{item.indexOf("Hekim") >= 0 ? "Sağlayabileceğiniz Kişi" : "Sağlayabileceğiniz Miktar"}</th>
                                                                                : <th width={180}>Sağlanan</th>
                                                                        )
                                                                }
                                                                {
                                                                    user.personType === 2 && formik.values._dataSource.status === 1
                                                                        ? <>
                                                                        </>
                                                                        : <th width={60}></th>
                                                                }
                                                                {
                                                                    formik.values._dataSource.status === 1
                                                                        ? <></>
                                                                        : <th>Açıklama</th>
                                                                }

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                _.map(_.filter(formik.values.items, i => i.category === item), (sItem, sIndex) => {
                                                                    return (
                                                                        <tr key={`table_row_${sIndex}`}>
                                                                            <td data-title="#">{sIndex + 1}</td>
                                                                            <td data-title="İhtiyaç">
                                                                                {sItem.name}
                                                                            </td>
                                                                            <td data-title={item.indexOf("Hekim") >= 0 ? "Sayı" : "Miktar"}>
                                                                                {sItem.quantity} {sItem.unit}
                                                                            </td>
                                                                            {
                                                                                user.personType === 2 && formik.values._dataSource.status === 1
                                                                                    ? <></>
                                                                                    :

                                                                                    (formik.values._dataSource.status === 1 ?
                                                                                        <td data-title={item.indexOf("Hekim") >= 0 ? "Sağlayabileceğiniz Kişi" : "Sağlayabileceğiniz Miktar"}>
                                                                                            <Input
                                                                                                onChange={({ target: { value } }) => onChangeGiveQuantity(value, sItem.id)}
                                                                                                type="number"
                                                                                                value={sItem.giveQuantity}
                                                                                                min={0}
                                                                                                maxLength="10"
                                                                                            />
                                                                                        </td>
                                                                                        : <td data-title="Sağlanan">{sItem.quantitySupplied} {sItem.unit}</td>)

                                                                            }
                                                                            {
                                                                                user.personType === 2 && formik.values._dataSource.status === 1
                                                                                    ? <>
                                                                                    </>
                                                                                    :

                                                                                    <td data-title="Birim">
                                                                                        {formik.values._dataSource.status === 1 ? sItem.unit : ''}
                                                                                    </td>
                                                                            }
                                                                            {
                                                                                formik.values._dataSource.status === 1 ?
                                                                                    <></>
                                                                                    : <td>{sItem.description}</td>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>

                                                </>
                                            ),
                                            expand: true,
                                            header: item,
                                            ...item
                                        }
                                    })
                                    }
                                />
                            </>
                            : <>
                            </>
                    }

                    {
                        formik.values._dataSource.status !== 1 ?
                            <>
                                {
                                    formik.values._supportingVets && formik.values._supportingVets.length > 0
                                        ?
                                        <>
                                            <h1 className="px-8 py-10 text-14 mt-20 font-bold bg-gray-200">Destek Veren Veteriner Hekimler</h1>
                                            <table className="table-with-text border-1">
                                                <thead className="bg-gray-100 text-left">
                                                    <tr>
                                                        <th className="px-8 py-4" width={30}>#</th>
                                                        <th className="px-8 py-4" width={300}>Adı</th>
                                                        <th className="px-8 py-4" width={200}>Gsm</th>
                                                        <th className="px-8 py-4">Açıklama</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        _.map(formik.values._supportingVets, (item, index) => {
                                                            return (
                                                                <tr key={`tr_sv_${index + 1}`}>
                                                                    <td data-title="#" className="px-8 py-4">{index + 1}</td>
                                                                    <td data-title="Adı" className="px-8 py-4">{item.name}<br /><small>{item.corporationName}</small></td>
                                                                    <td data-title="Gsm" className="px-8 py-4">{item.gsm}</td>
                                                                    <td data-title="Açıklama" className="px-8 py-4">{item.description}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </>

                                        : <>
                                        </>
                                }

                                {
                                    formik.values._dataSource.resultDescription && formik.values._dataSource.resultDescription !== "" ?
                                        <>
                                            <h1 className="px-8 py-4 text-14 font-bold mt-20">Ek Açıklamalar</h1>
                                            <hr />
                                            <p className="px-8 py-4 mt-5">
                                                {formik.values._dataSource.resultDescription}
                                            </p>
                                        </>
                                        : <></>
                                }


                            </>
                            : <>
                            </>
                    }

                    {
                        supportingItems.length > 0 && user.personType === 2 ?
                            <>
                                <h1 className="px-8 py-4 text-18 font-bold mt-20">Gelen Destekler</h1>
                                <hr />
                                {
                                    _.map(supportingItems, (item, index) => {
                                        return (
                                            <>
                                                <div className="text-14 mt-20 border-1">
                                                    <div className="bg-gray-200">
                                                        <h1 className="px-8 py-4 font-bold">{item.personName} - {item.personGsm}</h1>
                                                        <small className="px-8 py-4 text-12">{item.personCorporationName}, {item.personCity}/{item.personTown}</small>
                                                        <small className="px-8 py-4 text-12">{Moment(item.cDate).format('DD.MM.YYYY HH:mm')}</small>
                                                    </div>

                                                    {_.map(item.values, (vItem, vIndex) => {
                                                        return (
                                                            <div className="px-8 py-4 mt-5">
                                                                <h1 className="font-bold bg-gray-200 px-8 py-4 text-13">{vItem.category}</h1>
                                                                <table className={vIndex > 0 ? "table-with-text" : "table-with-text"}>
                                                                    <thead>
                                                                        <tr className="bg-gray-100">
                                                                            <th width={40} className="px-8 py-4">#</th>
                                                                            <th className="px-8 py-4">İhtiyaç</th>
                                                                            <th width={200} className="px-8 py-4">İhtiyaç Miktarı</th>
                                                                            <th width={200} className="px-8 py-4">Sağlayabileceği Miktar</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            _.map(vItem.subItems, (sItem, sIndex) => {
                                                                                return (
                                                                                    <tr key={`tr_si_${sIndex}`}>
                                                                                        <td data-title="#" className="px-8 py-4">{sIndex + 1}</td>
                                                                                        <td data-title="İhtiyaç" className="px-8 py-4">{sItem.itemName}</td>
                                                                                        <td data-title="İhtiyaç Miktarı" className="px-8 py-4">{sItem.itemQuantity} {sItem.itemUnit}</td>
                                                                                        <td data-title="Sağlayabileceği Miktar" className="px-8 py-4">{sItem.giveQuantity} {sItem.itemUnit}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>


                                            </>
                                        )
                                    })
                                }
                            </>
                            : <></>
                    }


                    <div className="flex justify-between mt-16">
                        <Button
                            icon={<IconBack width={12} />}
                            className="flex-6"
                            onClick={() => navigate("/general-status")}
                            text="Listeye Dön"
                            variant="default"
                        />
                        {
                            formik.values._dataSource.status === 1 && user.personType === 1 ?

                                <Button
                                    isProgress={query.isAjax}
                                    className="ml-auto flex-6"
                                    onClick={formik.submitForm}
                                    text="Destek Sağlama Talebi Gönder"
                                    variant="primary"
                                />
                                : <></>
                        }
                    </div>
                </div>
            </div>
        </MainLayout >
    );
}

export default GeneralStatusDetailPage;
