// Packages
import React from "react";


const Footer = () => {
    return (
        <div className="bg-white border-t-1">
            <div className="container !py-16">
                <div className="justify-between items-center text-13 text-gray-600 lg:flex">
                    <div>
                        <strong>Hasvet / E-vet</strong> tarafından projelendirilmiştir.
                    </div>
                    <div>Copyright &copy; {new Date().getFullYear()} <strong>E-vet</strong></div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
