// Packages
import React, {forwardRef, useMemo} from "react";
import PropTypes from "prop-types";
import cn from "classnames";
// Utils
import {IconCheck} from "../../../utils/icons";
// Styles
import styles from "./styles";


const Checkbox = forwardRef(({
    label,
    labelClassName,
    useFor,
    checked,
    onChange,
    errorMessage,
    ...props
}, ref) => {
    const _item = useMemo(() => {
        return (
            <>
                <label className={styles.wrapper}>
                    <input
                        ref={ref}
                        type="checkbox"
                        {...props}
                        onChange={() => onChange && onChange(!checked)}
                    />
                    <div className={
                        cn(
                            styles.input.common,
                            styles.input.size[useFor === "inputGroup" ? "small" : "default"],
                            checked
                                ? styles.input.checked
                                : styles.input.unchecked
                        )
                    }
                    >
                        {checked && (
                            <IconCheck
                                className="text-white"
                                width={useFor === "inputGroup" ? 12 : 14}
                            />
                        )}
                    </div>
                    {label && <div className={cn(styles.label, labelClassName)}>{label}</div>}
                </label>
                {errorMessage && (
                    <div className="bg-red-200 text-11 text-red-700 text-left mt-3 px-5 py-2">{errorMessage}</div>
                )}
            </>
        )
    }, [label, useFor, props, errorMessage, labelClassName]);

    return (
        useFor === "inputGroup"
            ? (
                <div className="flex items-center justify-center bg-gray-200 px-12 h-full">{_item}</div>
            )
            : _item
    )
});

Checkbox.defaultProps = {
    checked: false,
    useFor: "default"
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    errorMessage: PropTypes.string,
    label: PropTypes.any,
    labelClassName: PropTypes.string,
    onChange: PropTypes.func,
    useFor: PropTypes.oneOf(["default", "inputGroup"])
}

Checkbox.displayName = "Checkbox";
export default Checkbox;
