// Packages
import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from "react-helmet-async";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
// Store
import store from "./redux/store";
// Routing
import Routing from "./core/Routing";
// Styles
import "./utils/styles/global.scss";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
        <Provider store={store}>
            <Router>
                <Routing/>
            </Router>
        </Provider>
    </HelmetProvider>
);
