// Packages
import React, {useMemo} from "react";
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "lodash";
// Utils
import {IconSpin} from "../../../utils/icons";
// Styles
import styles from "./styles";


const Button = ({
    as,
    block,
    children,
    disabled,
    href,
    icon,
    iconPosition,
    isProgress,
    text,
    variant,
    outline,
    onClick,
    className,
    size,
    type,
    ...props
}) => {
    const {
        Component,
        componentProps,
        iconWrapperClassName
    } = useMemo(() => {
        const Component = href ? (_.some(['http', 'tel:', 'mailto:'], item => _.includes(href, item)) ? "a" : RouterLink) : (as || "button");
        const componentProps = _.assign(
            {},
            props,
            {
                className: cn(
                    styles.common,
                    block && styles.block,
                    icon && styles.wrapperForIconPosition[iconPosition],
                    styles.variant[outline ? "outline" : "normal"][variant],
                    size && styles.size[size],
                    disabled && styles.disabled,
                    isProgress && styles.disabled,
                    className
                )
            },
            onClick && {onClick},
            disabled && {disabled},
            isProgress && {disabled: true},
            Component === 'button' && {type},
            Component === 'a' && {
                href,
                role: 'button'
            },
            Component === RouterLink && {to: href}
        )

        const iconWrapperClassName = text && styles.iconWrapper[iconPosition];

        return {
            Component,
            componentProps,
            iconWrapperClassName
        }
    }, [as, block, outline, icon, iconPosition, variant, type, href, className, onClick, size, disabled, isProgress]);

    return (
        <Component {...componentProps}>
            {
                isProgress
                    ? (
                        <div className={styles.isProgress.wrapper}>
                            <IconSpin
                                className={styles.isProgress.icon}
                                width={24}
                            />
                            <span className={styles.isProgress.text}>Lütfen bekleyiniz...</span>
                        </div>
                    )
                    : (
                        <>
                            {icon && (
                                <span className={iconWrapperClassName}>{icon}</span>
                            )}
                            {_.isNull(text) ? children : <span className={"whitespace-nowrap"}>{text}</span>}
                        </>
                    )
            }
        </Component>
    )
};

Button.defaultProps = {
    as: "button",
    block: false,
    disabled: false,
    iconPosition: "left",
    isProgress: false,
    outline: false,
    type: "button",
    variant: "primary"
}

Button.propTypes = {
    as: PropTypes.string,
    block: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.any,
    iconPosition: PropTypes.oneOf([
        "left",
        "top",
        "right",
        "bottom"
    ]),
    isProgress: PropTypes.bool,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    size: PropTypes.oneOf([
        "small",
        "large"
    ]),
    text: PropTypes.string,
    type: PropTypes.oneOf([
        "button",
        "reset",
        "submit"
    ]),
    variant: PropTypes.oneOf([
        "default",
        "orange",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "light",
        "dark"
    ])
}

export default Button;
