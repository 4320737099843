// Packages
import React, {useMemo} from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import InputMask from "react-input-mask";
import _ from "lodash";


const Input = ({
    className,
    errorMessage,
    label,
    multiline,
    required,
    value,
    onChange,
    type,
    ...props
}) => {
    const Component = type === "gsm" ? InputMask : (multiline ? "textarea" : "input");

    const inputProps = useMemo(() => {
        return (
            _.assign(
                {},
                {...props},
                {
                    className: cn(
                        "w-full border-1 px-8 text-14 rounded-0 focus:border-blue-500",
                        {"py-8": multiline},
                        {"h-38": !multiline},
                        {"border-red-500": errorMessage},
                        className
                    ),
                    value,
                    type
                },
                onChange && {onChange},
                type === "gsm" && {
                    mask: '+\\90 (999) 999 99 99'
                }
            )
        )
    }, [props, className, multiline, errorMessage, onChange, type, value]);

    return (
        <>
            {label && <label className={cn(
                "text-13 font-medium mb-4 block",
                required ? "text-red-500" : "text-gray-800"
            )}>{label}</label>}
            <Component {...inputProps}/>
            {errorMessage && (
                <div className="bg-red-200 text-11 text-red-700 text-left mt-3 px-5 py-2">{errorMessage}</div>
            )}
        </>
    )
};

Input.defaultProps = {
    multiline: false,
    required: false
}

Input.propTypes = {
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any
}

export default Input;
