// Packages
import {useWindowSize} from "react-use";
// Constants
import {SIZE_DESKTOP_MIN, SIZE_TABLET_MIN} from "../../core/Constants/COMMON";


const useDevice = () => {
    const {width} = useWindowSize();
    const isDesktop = width >= SIZE_DESKTOP_MIN;
    const isMobile = width < SIZE_TABLET_MIN;

    return {
        device: isDesktop ? "desktop" : isMobile ? "mobile" : "tablet",
        isDesktop,
        isMobile,
        isTablet: !isDesktop && !isMobile,
    };
}

export default useDevice;
