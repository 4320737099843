// Packages
import React from "react";


const IconUser = props => (
    <svg
        fill="currentColor"
        viewBox="0 0 17 20"
        {...props}
    >
        <path
            d="M13.754 12a2.25 2.25 0 012.25 2.249v.918a2.75 2.75 0 01-.513 1.599C13.945 18.929 11.42 20 8 20c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 01-.51-1.595v-.92a2.25 2.25 0 012.249-2.25h11.501l.001.002zM8 .004a5 5 0 110 10 5 5 0 010-10z"
        />
    </svg>
)

export default IconUser;
