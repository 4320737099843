// Packages
import * as yup from "yup";
// Constants
import {STORAGE_KEY_AUTH_TOKENS} from "./Constants/COMMON";


export const StorageHelper = {
    clearAccessToken: () => localStorage.removeItem(STORAGE_KEY_AUTH_TOKENS),
    hasToken: () => {
        const val = localStorage.getItem(STORAGE_KEY_AUTH_TOKENS);
        return val !== null && val !== undefined;
    },
    getAuthTokens: () => {
        const val = localStorage.getItem(STORAGE_KEY_AUTH_TOKENS);
        return val
            ? JSON.parse(val)
            : ({
                refreshToken: undefined,
                refreshTokenExpireDate: undefined,
                token: undefined,
                tokenExpireDate: undefined,
            })
    },
    setAuthTokens: ({
        token,
        tokenExpireDate,
        refreshToken,
        refreshTokenExpireDate
    }) => {
        const data = {
            refreshToken,
            refreshTokenExpireDate,
            token,
            tokenExpireDate
        };
        return localStorage.setItem(STORAGE_KEY_AUTH_TOKENS, JSON.stringify(data));
    },
}

export const LocaleHelper = {
    setLocale: () => {
        yup.setLocale({
            string: {
                email: "Geçerli bir email adresi giriniz!",
            },
            mixed: {
                required: "Bu alan zorunludur!"
            }
        })
    }
}

export const StringHelper = {
    generatePhoneFromMask: value => {
        if (value) {
            return value
                .replace(/ /g, "")
                .replace("+90", "")
                .replace("(", "")
                .replace(/_/g, "")
                .replace(")", "");
        }

        return null;
    }
}
