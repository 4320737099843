// Packages
import React from "react";
// Layouts
import { ContentLayout } from "../../layouts";


const Gdpr = () => {
    return (
        <ContentLayout
            title="KVKK AYDINLATMA METNİ"
        >
            <p><strong>KİŞİSEL VERİLERİN KORUNMASI KANUNU AYDINLATMA METNİ</strong><br />
                <br />
                <strong>Tanımlar</strong>&nbsp;</p><p>İş bu aydınlatma metninde ge&ccedil;en;</p>
            <p><strong>Kişisel Veri:</strong> Kimliği belirli veya belirlenebilir ger&ccedil;ek kişiye ilişkin her t&uuml;rl&uuml; bilgiyi;</p><p><strong>Kişisel Verilerin Korunması Kanunu (KVKK):</strong> 7 Nisan 2016 tarihinde Resmi Gazete&rsquo;de yayınlanarak y&uuml;r&uuml;rl&uuml;ğe giren 6698 sayılı Kişisel Verilerin
                Korunması Kanunu&rsquo;nu,</p><p><strong>Veri İşleyen: </strong>Veri Sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen ger&ccedil;ek veya t&uuml;zel kişiyi,</p><p><strong>Veri Sorumlusu:</strong> Kişisel Verilerin işleme ama&ccedil;larını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve y&ouml;netilmesinden sorumlu olan ger&ccedil;ek veya t&uuml;zel kişiyi,</p><p>ifade eder.</p>
            <p>EVET ARAŞTIRMA GELİŞTİRME MÜH. YAZILIM SİST. MEDİKAL DAN. VET HİZ.TUR.SAN.VE TİC.A.Ş. olarak Veri Sorumlusu sıfatıyla, sizi KVKK kapsamındaki aydınlatma y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;m&uuml;z &ccedil;er&ccedil;evesinde, veri sorumlusunun kimliği, işlenen kişisel veri &ouml;rnekleri, kişisel verilerin işlenme ama&ccedil;ları, kişisel verilerin saklanma s&uuml;resi, kişisel verilerinizin &uuml;&ccedil;&uuml;nc&uuml; kişilere ve/veya yurtdışına aktarımı, kişisel verilerinizin toplanma y&ouml;ntemleri ve hukuki sebepleri ve KVKK
                kapsamında size tanınan haklara ilişkin olarak bilgilendirmek istiyoruz.</p><p>&nbsp;</p><p><strong>1. Veri Sorumlusunun Kimliği KVKK uyarınca muhatap Veri Sorumlusu:</strong></p><p>Antalya Ticaret Sicilinde 75999 sicil numarası ile kayıtlı, 0382022786391416 Mersis numaralı, G&Ouml;KSU MAH. GAZİ BULVARI NO: 465/9 KEPEZ / ANTALYA adresinde bulunan EVET ARAŞTIRMA GELİŞTİRME MÜH. YAZILIM SİST. MEDİKAL DAN. VET HİZ.TUR.SAN.VE TİC.A.Ş. &lsquo;dir.</p><p>&nbsp;</p><p><strong>2. Hangi
                    Kişisel Verilerinizi İşlemekteyiz?&nbsp;</strong></p><p>İşlediğimiz Kişisel Verilerinize &ouml;rnek olarak:</p><p>Tarafınızca Sağlanan Kişisel Veriler:</p><p>Kimlik verisi olarak Adı-soyadı, İletişim verisi olarak telefon numarası, Fax, mail, web adresi, Finansal veri olarak; hesap numarası ve iban numarası, işlem verisi olarak İp, Mac adresi, Log kayıtları ve sair kanallar &uuml;zerinden herhangi bir şekilde tarafımızla paylaştığınız diğer her t&uuml;rl&uuml; Kişisel
                        Verilerinizdir.</p><p>Otomatik Yollarla Toplanan Kişisel Veriler: &nbsp;&Ccedil;erezler vasıtasıyla veya diğer vasıtalarla otomatik şekilde toplanan Kişisel Verilerinizdir.</p><p>Diğer Kaynaklardan Alınan Kişisel Veriler: Sosyal medya ara&ccedil;ları, iş ortaklarımız, tedarik&ccedil;ilerimiz ve diğer &uuml;&ccedil;&uuml;nc&uuml; kişilerin, daha &ouml;nce vermiş olduğunuz izninize dayalı olarak tarafımızla paylaştığı g&uuml;ncellenmiş teslimat ve adres bilgileri; hesap
                            bilgileri, satın alma, sayfa g&ouml;r&uuml;nt&uuml;leme bilgileri; aranan terim ve arama sonu&ccedil;ları gibi bilgiler, &ouml;denmiş listelemeler (Sponsorlu Bağlantılar gibi) gibi Kişisel Verilerinizdir.</p><p>&nbsp;</p><p><strong>3. Kişisel Veri Saklama S&uuml;resi</strong></p><p>Kişisel Verileriniz, KVKK başta olmak &uuml;zere Kişisel Verilerin korunmasına ilişkin mevzuata ve diğer mevzuatta zorunlu kılınan s&uuml;relere uygun şekilde her hal&uuml;k&acirc;rda ve
                                mevzuatta bir s&uuml;re &ouml;ng&ouml;r&uuml;lmemiş ise kişisel veri işleme amacının gerektirdiği s&uuml;re kadar muhafaza edilir. Tutulan veriler verinin işlenmesini gerektiren sebepler sona erdikten sonra silinir, yok edilir veya anonim hale getirilir.</p><p>&nbsp;</p><p><strong>4. Kişisel Verilerinizin İşlenme Ama&ccedil;ları</strong></p><p>Kişisel Verileriniz kural olarak a&ccedil;ık rızanıza istinaden tarafımızca işlenmektedir. Lakin KVKK
                                    tarafından &ouml;ng&ouml;r&uuml;len temel ilkelere uygun olarak Madde 5.2 ve Madde 6.3 kapsamındaki kanuni y&uuml;k&uuml;ml&uuml;l&uuml;klerimizi yerine getirmek, bir s&ouml;zleşmenin kurulması veya ifası, hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerimizi yerine getirmek, bir hakkın tesisi, kullanılması veya korunması ve temel hak ve &ouml;zg&uuml;rl&uuml;klerinize zarar vermeksizin, meşru menfaatlerimizin korunması amacıyla ve alenileştirdiğiniz kişisel veriler bakımından
                                    a&ccedil;ık rızanız olmaksızın da işlenebilmektedir. Bu kapsamda yukarıdaki durumların mevcut olması halinde</p><p>İnternet Siteleri &uuml;zerinden sipariş edilen veya satın alınan &uuml;r&uuml;nlerin tarafınıza sağlanması, bu kapsamda gerekli durumlarda iletişime ge&ccedil;ilmesi, alım-satım işleminin yapılabilmesi, POS kullanımı, iade işlemleri, nakliye hizmetleri, elektronik ticaret kapsamında mesafeli satım s&ouml;zleşmesinin kurulması, &uuml;r&uuml;n &nbsp;satışı,
                                        tedariki, teslimi, soru ve şikayetlerinizin alınması, size cevap verilebilmesi, gerektiğinde olası bir uyuşmazlıkta bunların kullanılması, chat box, iletişim alanı hizmet kalitesinin g&ouml;zlemlenmesi gibi meşru menfaatlerimizi korumak amacıyla Kişisel Verilerinizi işlemekteyiz.</p><p>Kişisel verileriniz işbu tam Aydınlatma Metni&rsquo;nde belirtilen ama&ccedil;lar dahilinde KVKK Madde 5.1 ve Madde 6.2 kapsamında a&ccedil;ık rızanızın alınması şartı ile
                                            işlenebilmektedir. &nbsp;</p><p>&nbsp;</p><p><strong>5. Kişisel Verilerinizin &Uuml;&ccedil;&uuml;nc&uuml; Kişilere ve/veya Yurtdışına Aktarılması</strong></p><p>Kişisel Verileriniz yukarıda KVKK Madde 5.2 ve Madde 6.3 kapsamında belirlenen ama&ccedil;ların varlığı halinde a&ccedil;ık rızanız aranmaksızın yahut Aydınlatma Metni&rsquo;nde yer alan ama&ccedil;larla KVKK Madde 5.1 ve Madde 6.3 kapsamında a&ccedil;ık rızanıza istinaden yurti&ccedil;inde ve yurtdışında bulunan
                                                depolama, arşivleme, bilişim teknolojileri desteği (sunucu, hosting, program, bulut bilişim), g&uuml;venlik, &ccedil;ağrı merkezi, satış, pazarlama gibi alanlarda destek aldığımız &uuml;&ccedil;&uuml;nc&uuml; kişilere, işbirliği yapılan ve/veya hizmet alınan grup şirketlerine, iş ortaklarına, tedarik&ccedil;i firmalar, bankalar, finans kuruluşları, hukuk, vergi vb. alanlarda destek alınan danışmanlık firmalarına, satış, pazarlama ile kurum ve kuruluşlara aktarılabilir,
                                                işlem s&uuml;recinde bu &uuml;&ccedil;&uuml;nc&uuml; kişiler tarafından gerekli değerlendirmenin yapılması amacıyla sınırlı &ouml;l&ccedil;&uuml;de işlenebilir ve devir halinde devralan taraf varlıklarla birlikte bu varlıklara bağlı değerler olan Kişisel Verilerinizi kendisi veri sorumlusu olacak şekilde işlemeye devam edebilir.</p><p>&nbsp;</p><p><strong>6. Kişisel Verilerin Toplanma Y&ouml;ntemi ve Hukuki Sebebi</strong></p>
            <p>Kişisel verileriniz, EVET ARAŞTIRMA GELİŞTİRME MÜH. YAZILIM SİST. MEDİKAL DAN. VET HİZ.TUR.SAN.VE TİC.A.Ş. tarafından; başvuru formları, &nbsp;web siteleri e-b&uuml;lten kayıt formları, &ccedil;erezler, &nbsp;gibi kanallarla; ilgili internet siteleri ve farklı hukuki sebeplere dayanarak EVET ARAŞTIRMA GELİŞTİRME MÜH. YAZILIM SİST. MEDİKAL DAN. VET HİZ.TUR.SAN.VE TİC.A.Ş. &rsquo;nin faaliyetlerini s&uuml;rd&uuml;rebilmesi i&ccedil;in KVKK ve ilgili sair mevzuat tarafından &ouml;ng&ouml;r&uuml;len esas ve usuller doğrultusunda işbu
                Aydınlatma Metni&rsquo;nde belirtilen ama&ccedil;larla da toplanabilmekte, işlenebilmekte ve aktarılabilmektedir. Ayrıca İnternet sitemizden daha iyi şekilde faydalanabilmeniz ve kullanıcı deneyiminizi artırabilmek amacıyla Cookie(&Ccedil;erez) kullanıyoruz. Cookie kullanılmasını istemiyorsanız tarayıcınızın ilgili ayarlarından Cookie&rsquo;leri devre dışı bırakabilir, silebilir ya da engelleyebilirsiniz. Cookie ayarlarınızı tarayıcınızdan değiştirmediğiniz s&uuml;rece bu
                sitede &ccedil;erez kullanımını kabul ettiğiniz varsayılacaktır.</p><p>&nbsp;</p><p><strong>7. Kişisel Verilerinize Erişim ve KVKK Kapsamında Haklarınız</strong></p><p>KVKK Madde 11 uyarınca EVET ARAŞTIRMA GELİŞTİRME MÜH. YAZILIM SİST. MEDİKAL DAN. VET HİZ.TUR.SAN.VE TİC.A.Ş. &rsquo;ne başvurarak; Kişisel Verilerinizin işlenip işlenmediğini &ouml;ğrenme, işlenmişse buna ilişkin bilgi talep etme, Kişisel Verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp
                    kullanılmadığını &ouml;ğrenme, yurt i&ccedil;inde veya yurt dışında kişisel verilerinizin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme, kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların d&uuml;zeltilmesini isteme, ama&ccedil;, s&uuml;re ve meşruiyet prensipleri dahilinde değerlendirilmek &uuml;zere kişisel verilerinizin işlenmesini gerektiren sebeplerin ortadan kalkması halinde silinmesini veya yok edilmesini isteme, Kişisel Verilerinizin
                    d&uuml;zeltilmesi, silinmesi ya da yok edilmesi halinde bu işlemlerin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme, işlenen kişisel verilerinizin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi durumunda aleyhinize bir sonucun ortaya &ccedil;ıkması halinde bu sonuca itiraz etme, Kişisel Verilerinizin kanuna aykırı olarak işlenmesi ve bu sebeple zarara uğramanız halinde zararın giderilmesini talep etme haklarına
                    sahipsiniz. Bu kapsamda, yukarıdaki haklarınıza ilişkin talebinizi &ldquo;info@e-vet.com.tr&rdquo; e-posta adresimize g&uuml;venli elektronik imzalı olarak veya kimliğinizi tespit edici belgeler ile ıslak imzalı bir dilek&ccedil;e ile elden teslim edebilir ya da adresimize noter aracılığıyla g&ouml;nderebilirsiniz.</p><p>EVET ARAŞTIRMA GELİŞTİRME MÜH. YAZILIM SİST. MEDİKAL DAN. VET HİZ.TUR.SAN.VE TİC.A.Ş. talebin niteliğine g&ouml;re talebi en kısa s&uuml;rede ve en
                        ge&ccedil; 30 (otuz) g&uuml;n i&ccedil;erisinde &uuml;cretsiz olarak sonu&ccedil;landıracaktır. Taleplerin EVET ARAŞTIRMA GELİŞTİRME MÜH. YAZILIM SİST. MEDİKAL DAN. VET HİZ.TUR.SAN.VE TİC.A.Ş. tarafından sonu&ccedil;landırılmasına ilişkin ayrıca bir maliyet doğması halinde kişisel verileri Koruma Kurulunca belirlenen tarifedeki &uuml;cretler EVET ARAŞTIRMA GELİŞTİRME MÜH. YAZILIM SİST. MEDİKAL DAN. VET HİZ.TUR.SAN.VE TİC.A.Ş. tarafından talep edilebilir. Kişisel verilerinizin a&ccedil;ık rıza
                        ile işlendiği hallerde s&ouml;z konusu a&ccedil;ık rızanızı geri almanız durumunda s&ouml;z konusu a&ccedil;ık rızaya dayalı işlemenin gerekli olduğu abonelik / &uuml;yelik programından &ccedil;ıkarılacağınızı ve s&ouml;z konusu işlemeler sayesinde yararlandığınız avantajlardan ilgili tarih itibariyle yararlandırılamayacağınızı &ouml;nemle belirtmek isteriz. Başvurunuzun a&ccedil;ık, anlaşılır bir şekilde ve kimlik ile adres bilgilerini tespit edici belgeleri de ekleyerek
                        yazılı ve ıslak imzalı olarak elden, postayla ya da noter kanalıyla G&Ouml;KSU MAH. GAZİ BULVARI NO: 465/9 KEPEZ / ANTALYA adresine ulaştırmanız gerekmektedir.</p><p>Kişisel Verilere ilişkin mevzuat ve uygulamamız kapsamındaki değişiklikleri internet sitemizin ilgili sayfasından her zaman takip edebilirsiniz.</p>

        </ContentLayout>
    );
};

export default Gdpr;
