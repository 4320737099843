// Packages
import * as yup from "yup";
// Core
import {StringHelper} from "../Helpers";


export const VALIDATION_SCHEMA_GET_SUPPORT = () =>
    yup.object({
        address: yup.string().nullable().required(),
        isValid: yup.bool().oneOf([true], "Girdiğiniz bilgilerin doğruluğunu onaylamanız gerekmektedir!"),
    });

export const VALIDATION_SCHEMA_GIVE_SUPPORT = () =>
    yup.object({
        isValid: yup.bool().oneOf([true], "Girdiğiniz bilgilerin doğruluğunu onaylamanız gerekmektedir!"),
    });

export const VALIDATION_SCHEMA_SMS_CODE = () =>
    yup.object({
        smsCode: yup.string().nullable().required()
    });

export const VALIDATION_SCHEMA_REGISTER = () => (
    yup.object({
        name: yup.string().nullable().required(),
        corporationName: yup.string().nullable().required(),
        gsm: yup.string().test("len", "Lütfen geçerli telefon numarası giriniz!", val => StringHelper.generatePhoneFromMask(val).length === 10).required(),
        emailAddress: yup.string().nullable().email(),
        address: yup.string().nullable().required(),
        gdprConfirm: yup.bool().oneOf([true], "Aydınlatma metnini okuyup, kabul etmeniz gerekmektedir!"),
        veterinarianConfirm: yup.bool().oneOf([true], "Veteriner hekim olduğunuzu onaylamanız gerekmektedir!")
    })
)

export const VALIDATION_SCHEMA_SIGN_IN = () => (
    yup.object({
        gsm: yup.string().test("len", "Lütfen geçerli telefon numarası giriniz!", val => StringHelper.generatePhoneFromMask(val).length === 10).required()
    })
)


export const VALIDATION_SCHEMA_CREATE_SUPPORT = () => (
    yup.object({
        title: yup.string().nullable().required(),
        name: yup.string().nullable().required(),
        address: yup.string().nullable().required(),
    })
)


