// Packages
import React from "react";


const IconGiveLoveHand = props => (
    <svg
        viewBox="0 0 512 512"
        {...props}
    >
        <g>
            <path
                fill="#EF3E5C"
                d="M305.67 51.59c14.09 14.09 8.61 35.62 9.2 63.19-27.57-.59-49.09 4.87-63.17-9.21-7.78-7.78-8.05-20.13-.6-27.58 6.74-6.75 13.64-6.6 24.06-2.92-3.68-10.42-3.83-17.32 2.92-24.06 7.46-7.46 19.81-7.2 27.59.58z"
            ></path>
            <path
                fill="#E42A53"
                d="M305.67 51.59c-7.78-7.78-20.13-8.04-27.59-.58-2.938 2.933-4.565 5.896-5.167 9.167 7.528-5.774 18.581-5.011 25.757 2.164 12.196 12.196 9.731 29.971 9.23 52.408 2.279-.022 4.601-.019 6.97.032-.59-27.571 4.89-49.101-9.2-63.191z"
            ></path>
            <path
                fill="#EF3E5C"
                d="M287.28 140.21c19.63 0 35.52 16.69 35.52 37.26 0 37.26-35.52 58.47-70.99 95.73-35.48-37.26-70.95-58.47-70.95-95.73 0-20.57 15.89-37.26 35.47-37.26 17.74 0 26.61 9.32 35.48 27.95 8.86-18.63 17.73-27.95 35.47-27.95z"
            ></path>
            <path
                fill="#E42A53"
                d="M183.61 190.97c0-20.57 15.89-37.26 35.47-37.26 17.74 0 26.61 9.32 35.48 27.95 8.86-18.63 17.73-27.95 35.47-27.95 14.738 0 27.367 9.409 32.746 22.804-.484-20.127-16.172-36.304-35.496-36.304-17.74 0-26.61 9.32-35.47 27.95-8.87-18.63-17.74-27.95-35.48-27.95-19.58 0-35.47 16.69-35.47 37.26a54.99 54.99 0 002.928 17.778 56.695 56.695 0 01-.178-4.278z"
            ></path>
            <path
                fill="#415A6B"
                d="M423.8 414.931l-17.46 51.489-137.3-46.569 17.46-51.49 17.49 5.94 62 21.02 36.56 12.4 5.29 1.8 15.96 5.41zm-25.89 19.92c1.6-4.74-.94-9.88-5.67-11.49-4.74-1.61-9.891.93-11.5 5.67-1.601 4.74.939 9.89 5.68 11.49 4.73 1.609 9.88-.931 11.49-5.67z"
            ></path>
            <path
                fill="#314754"
                d="M292.834 381.36L310.324 387.301 372.324 408.32 408.884 420.721 414.174 422.521 420.499 424.665 423.8 414.931 407.84 409.521 402.55 407.721 365.99 395.32 303.99 374.301 286.5 368.36 269.04 419.851 278.674 423.118z"
            ></path>
            <path
                fill="#415A6B"
                d="M225.5 368.36l17.46 51.49-137.3 46.569-17.46-51.488 15.96-5.41 5.29-1.8 36.56-12.4 62-21.02 17.49-5.941zm-99.92 72.161c4.74-1.601 7.28-6.75 5.68-11.49-1.61-4.74-6.76-7.28-11.5-5.67-4.73 1.61-7.27 6.75-5.67 11.49 1.61 4.739 6.76 7.279 11.49 5.67z"
            ></path>
            <path
                fill="#314754"
                d="M97.494 424.07L102.784 422.271 139.343 409.87 201.343 388.851 218.833 382.91 232.556 423.379 242.96 419.851 225.5 368.36 208.01 374.301 146.01 395.32 109.45 407.721 104.16 409.521 88.2 414.931 91.938 425.953z"
            ></path>
            <path
                fill="#FFC592"
                d="M121.28 230.86c.82-.23 1.68-.42 2.59-.58 36.44-6.61 80.55 90.06 84.11 119.75.89 7.11.58 15.51-.41 24.41l-61.56 20.88-36.56 12.4-5.29 1.79c3.17-72.96-37.06-72.19-51.02-127.33-8.35-32.77 18.14-160.05 44.02-166.88 16.17-4.22 27.89 16.5 30.11 28.73 4.68 25.17-3.61 66.8-13.06 90.45 1.8-1.6 4.17-2.82 7.07-3.62z"
            ></path>
            <path
                fill="#EFA873"
                d="M207.98 350.03c-3.56-29.69-47.67-126.36-84.11-119.75-.91.16-1.77.35-2.59.58-2.9.8-5.27 2.02-7.07 3.62 9.45-23.65 17.74-65.28 13.06-90.45-2.22-12.23-13.94-32.95-30.11-28.73-4.966 1.311-9.954 7.068-14.714 15.73 2.436-2.632 4.896-4.368 7.351-5.016 16.17-4.22 27.89 16.5 30.11 28.73 4.68 25.17-3.61 66.8-13.06 90.45 1.8-1.6 4.17-2.82 7.07-3.62.82-.23 1.68-.42 2.59-.58 36.44-6.61 80.55 90.06 84.11 119.75.602 4.806.646 10.209.311 15.949l6.643-2.253c.989-8.9 1.299-17.299.409-24.41z"
            ></path>
            <path
                fill="#FFC592"
                d="M390.72 230.86c2.9.8 5.271 2.02 7.08 3.62-9.46-23.65-17.75-65.28-13.069-90.45 2.22-12.23 13.939-32.95 30.109-28.73 25.88 6.83 52.37 134.11 44.021 166.881-13.96 55.14-54.19 54.37-51.021 127.33l-5.29-1.79-36.56-12.4-61.561-20.88c-.989-8.9-1.3-17.3-.41-24.41 3.561-29.69 47.671-126.36 84.11-119.75.911.159 1.771.349 2.591.579z"
            ></path>
            <path
                fill="#EFA873"
                d="M319.606 361.104c3.561-29.689 47.671-126.36 84.11-119.75.91.16 1.771.35 2.59.58 2.9.8 5.271 2.02 7.08 3.62-9.46-23.65-17.75-65.28-13.069-90.45 2.032-11.197 12.031-29.498 26.114-29.272-3.798-5.741-7.701-9.505-11.592-10.532-16.17-4.22-27.89 16.5-30.109 28.73-4.681 25.17 3.609 66.8 13.069 90.45-1.81-1.6-4.18-2.82-7.08-3.62a26.63 26.63 0 00-2.59-.58c-36.439-6.61-80.55 90.06-84.11 119.75-.89 7.11-.579 15.51.41 24.41l15.029 5.098c-.504-6.671-.539-12.939.148-18.434z"
            ></path>
            <path
                fill="#314754"
                d="M392.24 423.36c4.729 1.61 7.27 6.75 5.67 11.49-1.61 4.739-6.76 7.279-11.49 5.67-4.74-1.601-7.28-6.75-5.68-11.49 1.61-4.74 6.76-7.28 11.5-5.67zM131.26 429.03c1.6 4.74-.94 9.89-5.68 11.49-4.73 1.609-9.88-.931-11.49-5.67-1.6-4.74.94-9.88 5.67-11.49 4.74-1.61 9.89.93 11.5 5.67z"
            ></path>
            <path
                fill="#EFA873"
                d="M368.865 327.166a4.999 4.999 0 01-4.173-7.747c.488-.744 1.11-1.673 1.842-2.766 23.146-34.562 33.073-57.84 29.509-69.188-.886-2.817-2.678-4.667-5.641-5.823-1.03-.403-2.218-.723-3.533-.953a5 5 0 011.723-9.85c1.969.344 3.803.845 5.448 1.489 5.761 2.247 9.754 6.446 11.543 12.141 6.104 19.43-14.62 53.678-30.739 77.749a449.717 449.717 0 00-1.796 2.693 4.994 4.994 0 01-4.183 2.255zM143.135 326.541a4.994 4.994 0 01-4.183-2.255c-.476-.724-1.083-1.629-1.795-2.693-16.12-24.07-36.844-58.319-30.74-77.75 1.789-5.694 5.782-9.893 11.546-12.142 1.598-.637 3.391-1.128 5.447-1.487a5 5 0 011.721 9.851c-1.35.236-2.527.552-3.499.939-2.997 1.169-4.789 3.02-5.674 5.836-3.565 11.348 6.363 34.626 29.508 69.188.731 1.093 1.354 2.021 1.842 2.766a4.999 4.999 0 01-4.173 7.747z"
            ></path>
        </g>
    </svg>
)

export default IconGiveLoveHand;
