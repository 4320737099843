// Packages
import React from "react";


const IconCheck = props => (
    <svg
        fill="currentColor"
        viewBox="0 0 18 18"
        {...props}
    >
        <path
            d="M5.652 13.398L1.746 9.309a1.006 1.006 0 00-.725-.302c-.27.002-.528.116-.72.315-.19.2-.299.47-.301.753-.002.283.102.555.29.759l4.634 4.85c.193.203.455.316.728.316.274 0 .536-.114.729-.316L17.71 3.827c.188-.203.291-.476.289-.758a1.104 1.104 0 00-.302-.753c-.19-.2-.449-.313-.72-.316-.27-.002-.53.106-.724.303L5.652 13.398z"
        />
    </svg>
)

export default IconCheck;
