export const PAGE_URL_GET_SUPPORT = "/get-support";
export const PAGE_URL_PROVIDE_SUPPORT = "/provide-support";
export const PAGE_URL_GDPR = "/gdpr";
export const PAGE_URL_GENERAL_STATUS = "/general-status";
export const PAGE_URL_GENERAL_STATUS_DETAIL = "/general-status-detail/:id";
export const PAGE_URL_GENERAL_STATUS_EDIT = "/general-status-form/:id";
export const PAGE_URL_ROADMAP = "/roadmap";

export const PAGE_URL_SUPPORT_REQUEST_LIST = "/support-requests";
export const PAGE_URL_SUPPORT_REQUEST_DETAIL = "/support-request-detail/:id";

export const PAGE_URL_SUPPORTER_LIST = "/supporters";
export const PAGE_URL_SUPPORTER_DETAIL = "/supporter-detail/:id";

export const PAGE_URL_MEMBER_LIST = "/members";

export const PAGE_URL_MY_TASKS = "/my-tasks";
export const PAGE_URL_MY_TASK_DETAIL = "/my-task-detail/:id";