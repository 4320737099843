// Packages
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "lodash";

import Input from "../Input";
import {IconArrowDown} from "../../../utils/icons";

const Select = ({
    className,
    label,
    items,
    onChange,
    placeholder,
    required,
    value,
    ...props
}) => {
    return (
        <>
            {label && <label className={cn(
                "text-13 font-medium mb-4 block",
                required ? "text-red-500" : "text-gray-800"
            )}>{label}</label>}
            <div className="relative">
                <select
                    className={cn(
                        "h-38 w-full border-1 px-8 text-14 bg-white focus:border-blue-500 pr-32"
                    )}
                    onChange={onChange && onChange}
                    value={value}
                    {...props}
                >
                    {placeholder && <option>{placeholder}</option>}
                    {_.map(items, (item, index) => {
                        return (
                            <option key={index} value={item?.value}>{item?.label}</option>
                        )
                    })}
                </select>
                <IconArrowDown className="absolute text-gray-500 right-8 top-17" width={10}/>
            </div>
        </>
    )
};

Input.defaultProps = {
    items: [],
    required: false
}

Input.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    items: PropTypes.array,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.any
}

export default Select;
