// Packages
import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleMap, Marker, LoadScript, useJsApiLoader } from "@react-google-maps/api";
import cn from "classnames";
// Layouts
import { MainLayout } from "../../layouts";
// Utils
import { IconLifeGuard, IconNew, IconTrash } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";
// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_CATEGORIES, API_URL_CITIES, API_URL_GENERAL_STATUS_SAVE, API_URL_GENERAL_STATUS_BY_ID, API_URL_TOWNS_BY_CITY_ID } from "../../core/Constants/API_URL";
import { VALIDATION_SCHEMA_CREATE_SUPPORT } from "../../core/Constants/VALIDATION_SCHEMA";
// Components
import { Accordion, Button, Input, Select } from "../../components";
// Styles
import styles from "./styles";
import { StringHelper } from "../../core/Helpers";
import IconBack from "../../utils/icons/_back";

const GeneralStatusEditPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const townsOfCity = useRef();
    const selectedTownId = useRef();

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyBxtGNu4VE5V79X4-rfhrorqZkTG5hXsvU" // ,
        // ...otherOptions
    });

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const formik = useFormik({
        initialValues: {
            _cities: [],
            _towns: [],
            _location: {
                lat: 0,
                lng: 0
            },
            categories: [],
            id: id,
            title: "",
            idCity: 0,
            idTown: 0,
            name: "",
            corporationName: "",
            gsm: "",
            address: "",
            location: "",
            importanceLevel: 1,
            description: "",
            resultDescription: "",
            status: 1,
            items: [],
            deletedItems: [],
            appointedVets: [],
            supportingVets: [],
            deletedPersons: []
        },
        onSubmit: async data => {
            let _data = {
                id: data.id,
                title: data.title,
                idCity: data.idCity,
                idTown: data.idTown,
                title: data.title,
                person: data.name,
                corporationName: data.corporationName,
                gsm: StringHelper.generatePhoneFromMask(data.gsm),
                address: data.address,
                location: data.location,
                importanceLevel: data.importanceLevel,
                description: data.description,
                resultDescription: data.resultDescription,
                status: data.status,
                items: [],
                deletedItems: data.deletedItems.filter(t => t.id !== 0),
                appointedVets: data.appointedVets,
                supportingVets: data.supportingVets,
                deletedPersons: data.deletedPersons.filter(t => t.id !== 0)
            }

            _.forEach(data.categories, mainItem => {
                _.forEach(mainItem.subItem, subItem => {
                    if (subItem.name && subItem.quantity !== "" && subItem.quantity > 0) {
                        _data.items.push({
                            idCategory: mainItem.idCategory,
                            ...subItem
                        })
                    }
                })
            });


            if (_data.items.length === 0 && _data.description === "") {
                alert("En az 1 ihtiyaç kaydı veya açıklama metni giriniz!")
            }
            else if (_data.items.filter(t => t.name === "" || t.name === null).length > 0) {
                alert("İhtiyacın ne olduğunu belirtiniz!")
            }
            else if (_data.items.filter(t => isNaN(parseFloat(t.quantity))).length > 0) {
                alert("İhtiyaç sayı/miktar alanlarını doğru formatta giriniz!")
            }
            else if (_data.items.filter(t => isNaN(parseFloat(t.quantitySupplied))).length > 0) {
                alert("Sağlanan sayı/miktar alanlarını doğru formatta giriniz!")
            }
            else {
                const result = await query.requestAsync({
                    request: {
                        data: _data,
                        url: API_URL_GENERAL_STATUS_SAVE
                    }
                });
                //
                if (!result.isError) {
                    alert(`Kayıt gerçekleşti!`);
                    navigate("/general-status");
                }
            }
        },
        validationSchema: VALIDATION_SCHEMA_CREATE_SUPPORT()
    })

    useEffect(() => {
        const fetchData = async () => {
            let _items = [];
            let _idCity = 0, _idTown = 0;
            if (id > 0) {
                const currentStatusData = await query.requestAsync({
                    request: {
                        method: "get",
                        url: API_URL_GENERAL_STATUS_BY_ID.replace(":id", id)
                    }
                });

                if (!currentStatusData.isError) {
                    _items = currentStatusData.items;
                    _idCity = currentStatusData.idCity;
                    _idTown = currentStatusData.idTown;
                    await formik.setFieldValue('appointedVets', currentStatusData.appointedVets);
                    await formik.setFieldValue('supportingVets', currentStatusData.supportingVets);
                    await formik.setFieldValue('items', currentStatusData.items);

                    await formik.setFieldValue('id', currentStatusData.id);
                    await formik.setFieldValue('idCity', currentStatusData.idCity);
                    await formik.setFieldValue('idTown', currentStatusData.idTown);
                    await formik.setFieldValue('title', currentStatusData.title);
                    await formik.setFieldValue('address', currentStatusData.address);
                    await formik.setFieldValue('corporationName', currentStatusData.corporationName);
                    await formik.setFieldValue('gsm', StringHelper.generatePhoneFromMask(currentStatusData.gsm));
                    await formik.setFieldValue('location', currentStatusData.location);
                    await formik.setFieldValue('description', currentStatusData.description);
                    await formik.setFieldValue('resultDescription', currentStatusData.resultDescription);
                    await formik.setFieldValue('status', currentStatusData.status);
                    await formik.setFieldValue('importanceLevel', currentStatusData.importanceLevel);
                    await formik.setFieldValue('name', currentStatusData.name);

                    selectedTownId.current = _idTown;

                    if (currentStatusData.location && currentStatusData.location !== null && currentStatusData.location.length > 0) {
                        await formik.setFieldValue('_location', { lat: parseFloat(currentStatusData.location.split(',')[0]), lng: parseFloat(currentStatusData.location.split(',')[1]) });
                    }
                }
            }


            const categories = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_CATEGORIES
                }
            });

            if (!categories.isError) {
                await formik.setFieldValue("categories", _.map(_.orderBy(categories, ["sortNo"]), item => {
                    return {
                        ...item,
                        subItem: (
                            item.idCategory === 1
                                ? (
                                    _items.filter(t => t.idCategory === 1).length === 0 ?
                                        [
                                            {
                                                name: "Veteriner Hekim",
                                                quantity: 0,
                                                quantitySupplied: 0,
                                                description: "",
                                                unit: item.units.split(",")[0]
                                            },
                                            {
                                                name: "Veteriner Teknikeri / Teknisyeni",
                                                quantity: 0,
                                                quantitySupplied: 0,
                                                description: "",
                                                unit: item.units.split(",")[0]
                                            },
                                            {
                                                name: "Yardımcı Personel",
                                                quantity: 0,
                                                quantitySupplied: 0,
                                                description: "",
                                                unit: item.units.split(",")[0]
                                            }
                                        ] : _items.filter(t => t.idCategory === 1)
                                )
                                : (
                                    _items.filter(t => t.idCategory === item.idCategory).length === 0 ?
                                        [
                                            {
                                                name: "",
                                                quantity: 0,
                                                quantitySupplied: 0,
                                                description: "",
                                                unit: item.units.split(",")[0]
                                            }
                                        ] :
                                        _items.filter(t => t.idCategory === item.idCategory)
                                )
                        )
                    }
                }));
            }

            const cities = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_CITIES
                }
            });

            if (!cities.isError && cities.length > 0) {
                await formik.setFieldValue("_cities", _.map(cities, item => {
                    return {
                        value: item.idCity,
                        label: item.name
                    }
                }));

                if (_idCity === 0) {
                    await formik.setFieldValue("idCity", cities[0].idCity);
                }
            }
        }

        fetchData().then(p => p);

    }, [])


    useEffect(() => {
        const fetchData = async () => {
            const towns = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_TOWNS_BY_CITY_ID.replace(":id", formik.values.idCity)
                }
            });

            if (!towns.isError && towns.length > 0) {
                const _towns = _.map(towns, item => {
                    return {
                        value: item.idTown,
                        label: item.name
                    }
                });

                await formik.setFieldValue("_towns", _towns);

                townsOfCity.current = towns;

                if (formik.values.idTown === 0) {
                    selectedTownId.current = towns[0].idTown;
                    await formik.setFieldValue("idTown", towns[0].idTown);
                }
            }
        }

        formik.values.idCity && fetchData().then(p => p);
    }, [formik.values.idCity]);

    const onChangeAccordionElement = useCallback(async (value, name, idCategory, itemIndex) => {
        if ((name === "quantity" || name === "quantitySupplied") && parseFloat(value) < 0) {
            return;
        }

        if ((name === "quantity" || name === "quantitySupplied") && idCategory === 1 && value.indexOf('.') >= 0) {
            return;
        }


        let datas = formik.values.categories;
        const index = _.findIndex(datas, o => o.idCategory === idCategory);

        datas[index].subItem[itemIndex] = {
            ...datas[index].subItem[itemIndex],
            [name]: (name === "quantity" || name === "quantitySupplied") ? (value ? parseFloat(value) : value) : value
        };

        await formik.setFieldValue("categories", datas);

    }, [formik.values.categories]);

    const onChangeVet = useCallback(async (value, name, index) => {
        let datas = formik.values.appointedVets;
        datas[index] = {
            ...datas[index],
            [name]: value
        }

        await formik.setFieldValue("appointedVets", datas);

    }, [formik.values.appointedVets]);


    const onChangeSupporting = useCallback(async (value, name, index) => {
        let datas = formik.values.supportingVets;
        datas[index] = {
            ...datas[index],
            [name]: value
        }

        await formik.setFieldValue("supportingVets", datas);

    }, [formik.values.supportingVets]);

    const onAddAppointedVet = useCallback(async () => {
        let datas = formik.values.appointedVets;

        datas.push({
            id: 0,
            name: "",
            groupName: "",
            task: "Görevli",
            title: "",
            corporationName: "",
            gsm: "",
            description: ""
        });

        await formik.setFieldValue("appointedVets", datas);
    }, [formik.values.appointedVets]);

    const onAddSupportingVet = useCallback(async () => {
        let datas = formik.values.supportingVets;

        datas.push({
            id: 0,
            name: "",
            groupName: "",
            task: "Destek",
            title: "",
            corporationName: "",
            gsm: "",
            description: ""
        });

        await formik.setFieldValue("supportingVets", datas);
    }, [formik.values.supportingVets]);


    const onAddCategoryItem = useCallback(async (idCategory) => {
        let datas = formik.values.categories;
        const index = _.findIndex(datas, o => o.idCategory === idCategory);
        const item = _.find(datas, o => o.idCategory === idCategory);

        datas[index].subItem.push({
            id: 0,
            name: "",
            quantity: 0,
            quantitySupplied: 0,
            description: "",
            unit: item.units.split(",")[0],
            isDeleted: false
        });

        await formik.setFieldValue("categories", datas);
    }, [formik.values.categories]);

    const onRemoveCategoryItem = useCallback(async (idCategory, itemIndex) => {
        let datas = formik.values.categories;
        const index = _.findIndex(datas, o => o.idCategory === idCategory);
        let deletedItems = formik.values.deletedItems;
        if (deletedItems === undefined) {
            deletedItems = [];
        }
        deletedItems.push(datas[index].subItem[itemIndex]);

        datas[index].subItem.splice(itemIndex, 1);

        await formik.setFieldValue("deletedItems", deletedItems);
        await formik.setFieldValue("categories", datas);
    }, [formik.values.categories]);

    const onRemoveVetItem = useCallback(async (index) => {
        let datas = formik.values.appointedVets;
        let deletedPersons = formik.values.deletedPersons;
        if (deletedPersons === undefined) {
            deletedPersons = [];
        }
        deletedPersons.push(datas[index]);

        datas.splice(index, 1);

        await formik.setFieldValue("deletedPersons", deletedPersons);
        await formik.setFieldValue("appointedVets", datas);
    }, [formik.values.appointedVets]);


    const onRemoveSupportingItem = useCallback(async (index) => {
        let datas = formik.values.supportingVets;
        let deletedPersons = formik.values.deletedPersons;
        if (deletedPersons === undefined) {
            deletedPersons = [];
        }
        deletedPersons.push(datas[index]);

        datas.splice(index, 1);

        await formik.setFieldValue("deletedPersons", deletedPersons);
        await formik.setFieldValue("supportingVets", datas);

    }, [formik.values.supportingVets]);


    const _findLocationByAddress = useCallback(async () => {
        let town = _.find(townsOfCity.current, t => t.idTown === parseInt(selectedTownId.current));
        const geocoder = window.google ? new window.google.maps.Geocoder() : undefined;
        geocoder.geocode({ 'address': town.name }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                let _location = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
                formik.setFieldValue('_location', _location);
                formik.setFieldValue('location', _location.lat.toString() + ',' + _location.lng.toString());
            }
        });
    }, [formik.values._location]);

    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white  sticky -top-20 z-50">
                    <IconLifeGuard className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                className="text-16 mt-10"
                                onClick={() => navigate("/general-status")}
                                icon={<IconBack width={12} />}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800">Dayanışma Oluştur/Düzenle</h1>
                            <div className="block text-12 text-gray-500">Bu ekrandan koordinasyon/dayanışma ekranına ilan yayınlayabilir veya mevcut ilanı güncelleyebilirsiniz</div>
                        </div>
                    </div>
                </div>

                <div className="p-16 bg-white">

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Input
                                errorMessage={formik.touched.title && formik.errors.title}
                                label="Başlık"
                                onChange={({ target: { value } }) => formik.setFieldValue("title", value)}
                                required
                                value={formik.values.title}
                                maxLength="100"
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Input
                                errorMessage={formik.touched.name && formik.errors.name}
                                label="Yetkili"
                                onChange={({ target: { value } }) => formik.setFieldValue("name", value)}
                                required
                                value={formik.values.name}
                                maxLength="100"
                            />
                        </div>
                        <div className={styles.column}>
                            <Input
                                label="Gsm"
                                onChange={({ target: { value } }) => formik.setFieldValue("gsm", StringHelper.generatePhoneFromMask(value))}
                                value={formik.values.gsm}
                                type="gsm"
                            />
                        </div>
                        <div className={styles.column}>
                            <Input
                                label="Kurum/Klinik"
                                onChange={({ target: { value } }) => formik.setFieldValue("corporationName", value)}
                                value={formik.values.corporationName}
                                maxLength="200"
                            />
                        </div>
                    </div>


                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Select
                                items={formik.values._cities}
                                label="İl"
                                onChange={({ target: { value } }) => formik.setFieldValue("idCity", value)}
                                required
                                value={formik.values.idCity}
                            />
                        </div>

                        <div className={styles.column}>
                            <Select
                                items={formik.values._towns}
                                label="İlçe"
                                onChange={({ target: { value } }) => { formik.setFieldValue("idTown", value); selectedTownId.current = value; }}
                                required
                                value={formik.values.idTown}
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Input
                                errorMessage={formik.touched.address && formik.errors.address}
                                label="Adres"
                                multiline
                                onChange={({ target: { value } }) => formik.setFieldValue("address", value)}
                                required
                                rows={3}
                                value={formik.values.address}
                                maxLength="500"
                            />
                        </div>
                        <div className={styles.column}>
                            <label className="text-13 font-medium mb-4 block">Konum <span className="cursor-pointer underline" onClick={() => { _findLocationByAddress() }}>Sorgula</span>
                            </label>
                            {
                                formik.values._location.lat > 0 ?
                                    <GoogleMap
                                        zoom={15}
                                        center={
                                            {
                                                lat: formik.values._location.lat || -3.745,
                                                lng: formik.values._location.lng || -38.523
                                            }}
                                        mapContainerStyle={{ width: "100%", height: "200px" }}
                                    >

                                        <Marker
                                            position={{
                                                lat: formik.values._location.lat || -3.745,
                                                lng: formik.values._location.lng || -38.523
                                            }}
                                            title={formik.values.address}
                                            draggable
                                            onDragEnd={(e) => {
                                                formik.setFieldValue("_location", { lat: e.latLng.lat(), lng: e.latLng.lng() });
                                                formik.setFieldValue("location", e.latLng.lat().toString() + ',' + e.latLng.lng().toString())
                                            }
                                            }
                                        ></Marker>

                                    </GoogleMap>
                                    : <></>
                            }
                        </div>

                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <h1 className="text-14 font-bold">İhtiyaçlar</h1>

                            <Accordion
                                items={_.map(_.filter(formik.values.categories, o => o.categoryType !== 2), (item, index) => {
                                    return {
                                        body: (
                                            <>
                                                <table cellPadding={4} className="table-with-text">
                                                    <thead>
                                                        <tr>
                                                            <th className={cn("center", { "hidden": item?.idCategory === 1 })} width={32}></th>
                                                            <th>{item?.description}</th>
                                                            <th width={90}>İhtiyaç</th>
                                                            <th width={90}>Sağlanan</th>
                                                            <th width={90}>Birim</th>
                                                            <th>Açıklama</th>
                                                            <th className={cn({ "hidden": item?.idCategory === 1 })} width={32}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            _.map(item.subItem, (sItem, sIndex) => {
                                                                return (
                                                                    <tr key={`table_row_${sIndex}`}>
                                                                        <td data-title="#" className={cn("text-14 font-medium text-center", { "hidden": item?.idCategory === 1 })}>{sIndex + 1}</td>
                                                                        <td>
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeAccordionElement(value, "name", item?.idCategory, sIndex)}
                                                                                placeholder={item?.placeholder}
                                                                                disabled={item?.idCategory === 1}
                                                                                value={sItem.name}
                                                                                maxLength="200"
                                                                            />
                                                                        </td>
                                                                        <td data-title="İhtiyaç">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeAccordionElement(value, "quantity", item?.idCategory, sIndex)}
                                                                                type="number"
                                                                                value={sItem.quantity}
                                                                                min={0}
                                                                                maxLength="10"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Sağlanan">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeAccordionElement(value, "quantitySupplied", item?.idCategory, sIndex)}
                                                                                type="number"
                                                                                value={sItem.quantitySupplied}
                                                                                min={0}
                                                                                maxLength="10"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Birim">
                                                                            <Select
                                                                                items={_.map(item.units.split(","), (unitItem, unitIndex) => {
                                                                                    return {
                                                                                        label: unitItem,
                                                                                        value: unitItem
                                                                                    }
                                                                                })}
                                                                                onChange={({ target: { value } }) => onChangeAccordionElement(value, "unit", item?.idCategory, sIndex)}
                                                                                value={sItem.unit}
                                                                            />
                                                                        </td>
                                                                        <td data-title="Açıklama">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeAccordionElement(value, "description", item?.idCategory, sIndex)}
                                                                                placeholder="Açıklama"
                                                                                value={sItem.description}
                                                                                maxLength="500"
                                                                            />
                                                                        </td>
                                                                        <td data-title="" className={cn({ "!hidden": item?.idCategory === 1 })}>
                                                                            {
                                                                                sIndex !== 0 && (
                                                                                    <div
                                                                                        className="text-red-500 text-center cursor-pointer"
                                                                                        onClick={() => onRemoveCategoryItem(item.idCategory, sIndex)}
                                                                                    >
                                                                                        <IconTrash width={32} className="text-red-500" />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>

                                                {
                                                    item.idCategory !== 1 && (
                                                        <Button
                                                            className="mt-6"
                                                            icon={<IconNew width={16} />}
                                                            onClick={() => onAddCategoryItem(item.idCategory)}
                                                            text="Satır Ekle"
                                                            variant="light"
                                                        />
                                                    )
                                                }
                                            </>
                                        ),
                                        header: item.name,
                                        ...item
                                    }
                                })
                                }
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Accordion
                                items={
                                    [
                                        {
                                            header: "Görevlendirilen Hekimler",
                                            body: (<>
                                                <table className="table-with-text">
                                                    <thead className="bg-gray-100 text-left">
                                                        <tr>
                                                            <th className="px-8 py-4" width={30}>#</th>
                                                            <th className="px-8 py-4" width={150}>Ekip Adı</th>
                                                            <th className="px-8 py-4" width={150}>Ünvan</th>
                                                            <th className="px-8 py-4" width={200}>Adı</th>
                                                            <th className="px-8 py-4" width={200}>Kurum/Klinik</th>
                                                            <th className="px-8 py-4" width={200}>Gsm</th>
                                                            <th className="px-8 py-4">Açıklama</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            _.map(formik.values.appointedVets, (item, index) => {
                                                                return (
                                                                    <tr key={`table_av_${index}`}>
                                                                        <td data-title="#" className="px-8 py-4">{index + 1}</td>
                                                                        <td data-title="Ekip Adı" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeVet(value, "groupName", index)}
                                                                                placeholder="Ekip Adı"
                                                                                value={item.groupName}
                                                                                maxLength="100"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Ünvan" className="px-8 py-4">
                                                                            <Select
                                                                                items={[
                                                                                    { value: "Veteriner Hekim", label: "Veteriner Hekim" },
                                                                                    { value: "Veteriner Teknikeri / Teknisyeni", label: "Veteriner Teknikeri / Teknisyeni" },
                                                                                    { value: "Yardımcı Personel", label: "Yardımcı Personel" },
                                                                                ]}
                                                                                onChange={({ target: { value } }) => onChangeVet(value, "title", index)}
                                                                                value={item.title}
                                                                            />
                                                                        </td>
                                                                        <td data-title="Adı" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeVet(value, "name", index)}
                                                                                placeholder="Adı"
                                                                                value={item.name}
                                                                                maxLength="100"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Kurum/Klinik/Firma" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeVet(value, "corporationName", index)}
                                                                                placeholder="Kurum/Klinik/Firma Adı"
                                                                                value={item.corporationName}
                                                                                maxLength="200"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Gsm" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeVet(StringHelper.generatePhoneFromMask(value), "gsm", index)}
                                                                                placeholder="Gsm"
                                                                                value={item.gsm}
                                                                                type="gsm"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Açıklama" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeVet(value, "description", index)}
                                                                                placeholder="Açıklama"
                                                                                value={item.description}
                                                                                maxLength="500"
                                                                            />
                                                                        </td>
                                                                        <td data-title="" className="px-8 py-4">

                                                                            <div
                                                                                className="text-red-500 cursor-pointer text-center"
                                                                                onClick={() => onRemoveVetItem(index)}>
                                                                                <IconTrash width={26} className="text-red-500" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>

                                                <Button
                                                    className="mt-6"
                                                    icon={<IconNew width={16} />}
                                                    onClick={() => onAddAppointedVet()}
                                                    text="Satır Ekle"
                                                    variant="light"
                                                />
                                            </>),
                                            expand: true
                                        },
                                        {
                                            header: "Destek Veren Hekimler",
                                            body: (<>
                                                <table className="table-with-text">
                                                    <thead className="bg-gray-100 text-left">
                                                        <tr>
                                                            <th className="px-8 py-4" width={30}>#</th>
                                                            <th className="px-8 py-4" width={150}>Ekip Adı</th>
                                                            <th className="px-8 py-4" width={150}>Ünvan</th>
                                                            <th className="px-8 py-4" width={200}>Adı</th>
                                                            <th className="px-8 py-4" width={200}>Kurum/Klinik</th>
                                                            <th className="px-8 py-4" width={200}>Gsm</th>
                                                            <th className="px-8 py-4">Açıklama</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            _.map(formik.values.supportingVets, (item, index) => {
                                                                return (
                                                                    <tr key={`table_sv_${index}`}>
                                                                        <td data-title="#" className="px-8 py-4">{index + 1}</td>
                                                                        <td data-title="Ekip Adı" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeSupporting(value, "groupName", index)}
                                                                                placeholder="Ekip Adı"
                                                                                value={item.groupName}
                                                                                maxLength="100"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Ünvan" className="px-8 py-4">
                                                                            <Select
                                                                                items={[
                                                                                    { value: "Veteriner Hekim", label: "Veteriner Hekim" },
                                                                                    { value: "Veteriner Teknikeri / Teknisyeni", label: "Veteriner Teknikeri / Teknisyeni" },
                                                                                    { value: "Yardımcı Personel", label: "Yardımcı Personel" },
                                                                                ]}
                                                                                onChange={({ target: { value } }) => onChangeSupporting(value, "title", index)}
                                                                                value={item.title}
                                                                            />
                                                                        </td>
                                                                        <td data-title="Adı" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeSupporting(value, "name", index)}
                                                                                placeholder="Adı"
                                                                                value={item.name}
                                                                                maxLength="100"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Kurum/Klinik/Firma" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeSupporting(value, "corporationName", index)}
                                                                                placeholder="Kurum/Klinik/Firma Adı"
                                                                                value={item.corporationName}
                                                                                maxLength="200"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Gsm" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeSupporting(StringHelper.generatePhoneFromMask(value), "gsm", index)}
                                                                                placeholder="Gsm"
                                                                                value={item.gsm}
                                                                                maxLength="50"
                                                                            />
                                                                        </td>
                                                                        <td data-title="Açıklama" className="px-8 py-4">
                                                                            <Input
                                                                                onChange={({ target: { value } }) => onChangeSupporting(value, "description", index)}
                                                                                placeholder="Açıklama"
                                                                                value={item.description}
                                                                                maxLength="500"
                                                                            />
                                                                        </td>
                                                                        <td data-title="" className="px-8 py-4">
                                                                            <div
                                                                                className="text-red-500 cursor-pointer text-center"
                                                                                onClick={() => onRemoveSupportingItem(index)}
                                                                            >
                                                                                <IconTrash width={26} className="text-red-500" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>

                                                <Button
                                                    className="mt-6"
                                                    icon={<IconNew width={16} />}
                                                    onClick={() => onAddSupportingVet()}
                                                    text="Satır Ekle"
                                                    variant="light"
                                                />
                                            </>),
                                            expand: true
                                        }
                                    ]
                                }
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Input
                                label="Açıklama"
                                multiline
                                onChange={({ target: { value } }) => formik.setFieldValue("description", value)}
                                rows={3}
                                value={formik.values.description}
                                maxLength="4000"
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Input
                                label="Ek Açıklamalar (Koordinasyon Sonrası için)"
                                multiline
                                onChange={({ target: { value } }) => formik.setFieldValue("resultDescription", value)}
                                rows={3}
                                value={formik.values.resultDescription}
                                maxLength="4000"
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Select
                                items={[
                                    { value: 1, label: "Acil" },
                                    { value: 2, label: "Normal" },
                                ]}
                                label="Öncelik"
                                onChange={({ target: { value } }) => formik.setFieldValue("importanceLevel", value)}
                                value={formik.values.importanceLevel}
                            />
                        </div>
                        <div className={styles.column}>
                            <Select
                                items={[
                                    { value: 1, label: "Destek Bekleniyor" },
                                    { value: 2, label: "Koordine Edildi" },
                                    { value: 3, label: "Tamamlandı" },
                                ]}
                                label="Durum"
                                onChange={({ target: { value } }) => formik.setFieldValue("status", value)}
                                value={formik.values.status}
                            />
                        </div>
                    </div>

                    <div className="flex justify-between mt-16">
                        <Button
                            className="flex-6"
                            icon={<IconBack width={12} />}
                            onClick={() => navigate("/general-status")}
                            text="Listeye Dön"
                            variant="default"
                        />
                        <Button
                            isProgress={query.isAjax}
                            className="ml-auto"
                            onClick={formik.submitForm}
                            text="Kaydet"
                            variant="primary"
                        />
                    </div>



                </div>
            </div>
        </MainLayout >
    );
}

export default GeneralStatusEditPage;
