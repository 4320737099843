// Packages
import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import cn from "classnames";
// Utils
import {IconMinus, IconNew} from "../../../utils/icons";
// Styles
import styles from "./styles";


const Accordion = ({
    allowMultipleExpand,
    items,
    classNameBody,
    classNameHeader,
    classNameWrapper,
    columnCount
}) => {
    const [expandIndexes, setExpandIndexes] = useState([]);

    const onChanged = useCallback(index => {
        let _data = [...expandIndexes];

        if (allowMultipleExpand) {
            _.indexOf(_data, index) === -1
                ? _data.push(index)
                : (_data = _.filter(_data, o => o !== index))


        } else {
            _data = _.indexOf(_data, index) === -1
                ? [index]
                : _.filter(_data, o => o !== index)
        }

        setExpandIndexes(_data);
    }, [allowMultipleExpand, expandIndexes]);

    useEffect(() => {
        let expandIndexList = [];
        _.forEach(items, (item, index) => {
            item.expand && expandIndexList.push(index);
        });

        setExpandIndexes(expandIndexList);
    }, [_.filter(items, o => o.expand).length]);

    return (
        <div>
            {
                _.map(_.chunk(items, columnCount), (subItems, subIndex) => {
                    return (
                        <div className={styles.row} key={`main_row_${subIndex}`}>
                            {
                                subItems.map((item, key) => {
                                    const _index = parseInt(key.toString() + subIndex.toString())
                                    const isExpand = _.indexOf(expandIndexes, _index) > -1;

                                    return (
                                        <div className={styles.column} key={`accordion_${key}_${subIndex}`}>
                                            <div
                                                className={cn(styles.wrapper, classNameWrapper)}
                                            >
                                                <div
                                                    className={cn(styles.header.wrapper, classNameHeader)}
                                                    onClick={() => onChanged(_index)}
                                                >
                                                    <div className={styles.header.title}>
                                                        {item.header && item.header.icon && item.header.icon}
                                                        {_.isObject(item.header) ? item.header.title : item.header}
                                                    </div>
                                                    {
                                                        isExpand
                                                            ? <IconMinus width={12}/>
                                                            : <IconNew width={12}/>
                                                    }
                                                </div>
                                                <div className={cn(
                                                    styles.body.wrapper,
                                                    classNameBody,
                                                    {"hidden": !isExpand}
                                                )}
                                                >
                                                    {item.body}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )

                    return "item"
                })
            }
        </div>
    )
};

Accordion.defaultProps = {
    allowMultipleExpand: true,
    columnCount: 1
};

Accordion.propTypes = {
    allowMultipleExpand: PropTypes.bool,
    classNameBody: PropTypes.string,
    classNameHeader: PropTypes.string,
    classNameWrapper: PropTypes.string,
    columnCount: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({
        body: PropTypes.any,
        expand: PropTypes.bool,
        header: PropTypes.any
    }))
}

export default Accordion;
