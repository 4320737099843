import cn from "classnames";

const styles = {
    supportButtons: cn(
        "flex items-center justify-center flex-1 m-8 bg-white p-20 cursor-pointer ",
        "hover:bg-blue-100"
    )
}

export default styles;
