// Packages
import React from "react";


const IconTrash = props => (
    <svg
        fill="none"
        viewBox="0 0 512 512"
        {...props}
    >
        <path
            d="M448 48H64c-17.673 0-32 14.327-32 32v16c0 17.673 14.327 32 32 32h384c17.673 0 32-14.327 32-32V80c0-17.673-14.327-32-32-32zM74.45 160a8 8 0 00-8 8.83l26.31 252.56a1.496 1.496 0 000 .22A47.998 47.998 0 00140.45 464h231.09a47.997 47.997 0 0047.67-42.39v-.21l26.27-252.57a8.004 8.004 0 00-4.734-8.152 8 8 0 00-3.266-.678H74.45zm248.86 180.69a16.004 16.004 0 014.85 11.362 16.003 16.003 0 01-16.12 16.113 15.996 15.996 0 01-11.36-4.855L256 318.63l-44.69 44.68a15.997 15.997 0 01-22.461-.159 15.995 15.995 0 01-4.688-11.196 15.996 15.996 0 014.519-11.265L233.37 296l-44.69-44.69a15.996 15.996 0 01-4.519-11.265 15.995 15.995 0 0115.886-15.879 15.997 15.997 0 0111.263 4.524L256 273.37l44.68-44.68a15.997 15.997 0 0122.461.159 16 16 0 01.169 22.461L278.62 296l44.69 44.69z"
            fill="currentColor"
        />
    </svg>
)

export default IconTrash;
