// Packages
import React from "react";


const IconNavigationTable = props => (
    <svg
        fill="currentColor"
        viewBox="0 0 30 30"
        {...props}
    >
        <path
            fill="#3F51B5"
            d="M20 28h-4V1a1 1 0 10-2 0v27h-4a1 1 0 100 2h10a1 1 0 100-2z"
        />
        <path
            fill="#2197F3"
            d="M30 8c0 .31-.15.61-.4.8l-4 3c-.17.13-.38.2-.6.2H1c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h24c.22 0 .43.07.6.2l4 3c.25.19.4.49.4.8zM30 15v6c0 .55-.45 1-1 1H5c-.22 0-.43-.07-.6-.2l-4-3c-.25-.19-.4-.49-.4-.8 0-.31.15-.61.4-.8l4-3c.17-.13.38-.2.6-.2h24c.55 0 1 .45 1 1z"
        />
    </svg>
)

export default IconNavigationTable;
