// Packages
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';

// Layouts
import MainLayout from "../../layouts/MainLayout";

// Utils
import { IconSpin } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";

// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_PERSON, API_URL_PERSON_SET_STATUS } from "../../core/Constants/API_URL";

// Components
import { Button, Input } from "../../components";
import { shallowEqual, useSelector } from "react-redux";
import IconChevronLeft from "../../utils/icons/_chevronLeft";
import IconChevronRight from "../../utils/icons/_chevronRight";
import IconBack from "../../utils/icons/_back";
import IconUser from "../../utils/icons/_user";
import _ from "lodash";
import IconAnglesLeft from "../../utils/icons/_anglesLeft";

const MemberListPage = () => {
    const user = useSelector(state => state.user, shallowEqual);
    const navigate = useNavigate();

    const isProgress = {
        icon: "animate-spin",
        text: "ml-8",
        wrapper: "flex items-center"
    }

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [recordCount, setRecordCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async (page) => {
        if (user.personType !== 2) {
            navigate('/');
            return;
        }

        let _url = API_URL_PERSON.replace(":page", page);
        if (searchTerm !== '') {
            _url += '?q=' + searchTerm;
        }

        const responseData = await query.requestAsync({
            request: {
                method: "get",
                url: _url
            }
        });

        setLoading(false);
        setPage(page);
        if (!responseData.isError && responseData.length > 0) {
            setPageCount(responseData[0].pageCount);
            setRecordCount(responseData[0].recordCount);
            setDataSource(responseData);
        }
    }

    const setStatusFlag = useCallback(async (id, statusFlag) => {
        const responseData = await query.requestAsync({
            request: {
                data: { statusFlag },
                method: "post",
                url: API_URL_PERSON_SET_STATUS.replace(":id", id)
            }
        });

        if (!responseData.isError) {
            let ds = dataSource;
            let index = ds.findIndex(t => t.idPerson === id)
            ds[index].statusFlag = statusFlag;
            setDataSource(ds);
        }

    }, [dataSource]);

    useEffect(() => {
        fetchData(page).then(p => p);
    }, [])


    const firstPage = async () => {
        await fetchData(1);
    }


    const lastPage = async () => {
        await fetchData(pageCount);
    }

    const prev = async () => {
        await fetchData(page - 1);
    }

    const next = async () => {
        await fetchData(page + 1);
    }


    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white sticky -top-20 z-50">
                    <IconUser className="absolute -right-0 -top-0 opacity-50" height={100} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                icon={<IconBack width={12} />}
                                onClick={() => navigate("/")}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800">Üye Listesi</h1>
                        </div>
                    </div>

                </div>

                {
                    loading ? <div className="p-16 text-20 bg-white text-center">
                        <IconSpin
                            className={isProgress.icon}
                            width={24}
                        />

                        Yükleniyor...
                    </div> :
                        (
                            recordCount === 0
                                ? <div className="p-16 text-14 bg-white">
                                    Şu anda kayıtlı üye bulunmamaktadır
                                </div>
                                :
                                <div className="p-16 text-14 bg-white">
                                    <div className="flex">
                                        <Input
                                            placeholder="Ara..."
                                            onChange={({ target: { value } }) => setSearchTerm(value)}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                  firstPage();
                                                }
                                              }}
                                        />
                                        <Button
                                            text="Ara"
                                            variant="default"
                                            onClick={() => firstPage()}
                                        />

                                    </div>
                                    <table className="table-with-text text-13 mt-5">
                                        <thead className="sticky top-50 z-50">
                                            <tr className="bg-gray-100">
                                                <th width={130} className="text-left px-8 py-8">Tarih</th>
                                                <th width={200} className="text-left">Adı</th>
                                                <th width={100} className="text-left">Gsm</th>
                                                <th width={250} className="text-left">Kurum/Klinik/Firma</th>
                                                <th width={200} className="text-left">İl/İlçe</th>
                                                <th width={200} className="text-left">Adres</th>
                                                <th width={100} style={{ textAlign: 'center' }}>Tipi</th>
                                                <th width={100} style={{ textAlign: 'center' }}>Durum</th>
                                            </tr>
                                        </thead>
                                        <tbody>{
                                            _.map(dataSource, (item, index) => {
                                                return (
                                                    <tr key={`item_${index}`} style={{ borderBottom: '1px solid #eee' }}>
                                                        <td data-title="Tarih" className="text-left px-8 py-8">
                                                            {Moment(item.cDate).format('DD.MM.YYYY HH:mm')}
                                                        </td>
                                                        <td data-title="Adı" className="text-left">
                                                            {item.name}
                                                        </td>
                                                        <td data-title="Gsm">{item.gsm}</td>
                                                        <td data-title="Kurum/Klinik/Firma">{item.corporationName}</td>
                                                        <td data-title="İl/İlçe" className="text-left">
                                                            {item.city}/{item.town}
                                                        </td>
                                                        <td data-title="Adres">{item.address}</td>
                                                        <td style={{ textAlign: 'center' }}>{item.personType === 1 ? "Kullanıcı" : "Koordinasyon"}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                item.statusFlag === true ?
                                                                    <label className="cursor-pointer text-success" onClick={() => setStatusFlag(item.idPerson, false)}>Aktif</label>
                                                                    : <label className="cursor-pointer text-danger" onClick={() => setStatusFlag(item.idPerson, true)}>İnaktif</label>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>

                                    <div className="mt-5 text-14 bg-white">
                                        Kayıt Sayısı : {(page - 1) * 50}-{(((page - 1) * 50) + 50) > recordCount ? recordCount : ((page - 1) * 50) + 50}/{recordCount}
                                    </div>

                                    <div className="flex justify-between mt-16">
                                        {
                                            page > 1
                                                ? <div className="flex">
                                                    <Button
                                                        isProgress={query.isAjax}
                                                        className="flex-6"
                                                        onClick={() => firstPage()}
                                                        text="<< İlk Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                    <Button
                                                        icon={<IconChevronLeft width={10} />}
                                                        className="flex-6"
                                                        isProgress={query.isAjax}
                                                        onClick={() => prev()}
                                                        text="Önceki Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    /></div>
                                                : <></>
                                        }

                                        {
                                            page < pageCount
                                                ?
                                                <div className="flex ml-auto">
                                                    <Button
                                                        icon={<IconChevronRight width={10} />}
                                                        iconPosition="right"
                                                        isProgress={query.isAjax}
                                                        onClick={() => next()}
                                                        text="Sonraki Sayfa"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                    <Button
                                                        isProgress={query.isAjax}
                                                        onClick={() => lastPage()}
                                                        text="Son Sayfa >>"
                                                        variant="default"
                                                        size="small"
                                                    />
                                                </div>

                                                : <></>
                                        }
                                    </div>
                                </div>
                        )
                }



            </div>
        </MainLayout>
    );
}

export default MemberListPage;
