// Packages
import React from "react";


const IconMinus = props => (
    <svg
        fill="currentColor"
        viewBox="0 0 488 92"
        {...props}
    >
        <path
            d="M.488 60.368V31.633C.488 14.469 14.402.554 31.567.554h424.866c17.164 0 31.079 13.914 31.079 31.079v28.735c0 17.164-13.914 31.079-31.079 31.079H31.567C14.403 91.446.488 77.532.488 60.368z"
        />
    </svg>
)

export default IconMinus;
