// Packages
import React from "react";
import { useNavigate } from "react-router-dom";
// Utils
import { IconLogo } from "../../utils/icons";

import { StorageHelper } from "../../core/Helpers";
import Button from "../UI/Button";

const TopMenu = () => {
    const navigate = useNavigate();
    const hasToken = StorageHelper.hasToken();

    return (
        <div className="w-full bg-white border-b-1">
            <div className="flex justify-between container !py-16">
                <IconLogo
                    className="cursor-pointer"
                    onClick={() => navigate("/")}
                    height={40}
                />
                <a className="ml-auto mr-10" href="https://tvhb.org.tr/" target={"_blank"}>
                    <img className="h-48" src="/assets/tvhb_logo.png" alt="Türk Veteriner Hekimleri Birliği" />
                </a>

                {
                    hasToken ?
                        <Button 
                            onClick={() => { StorageHelper.clearAccessToken();  window.location.reload(); }}
                            text="Çıkış"
                            variant="default"
                        />
                        : <></>
                }
            </div>
        </div>
    )
}

export default TopMenu;
