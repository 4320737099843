const styles = {
    body: {
        wrapper: "border-1 p-8 -mt-1"
    },
    column: "flex-1 px-4 py-0",
    header: {
        title: "flex-1 mr-12 text-13 font-medium",
        wrapper: "flex justify-between border-1 px-10 py-9 cursor-pointer bg-gray-200"
    },
    row: "flex -mx-4 flex-wrap",
    wrapper: "mt-8"
}

export default styles;
