// Packages
import React from "react";


const IconBarChartGraph = props => (
    <svg
        fill="currentColor"
        viewBox="0 0 500 468"
        {...props}
    >
        <path
            fill="#FFCE6C"
            d="M44.5 390.1H0v77.1h44.5v-77.1zM321 467.1l50.7-38.5-50.7-38.5H129.1v77H321zM110.1 390.1H63.5v77.1h46.6v-77.1z"
        />
        <path
            fill="#ED6F62"
            d="M44.5 292.8H0v77.1h44.5v-77.1zM449.3 369.8l50.7-38.5-50.7-38.5H129.1v77h320.2zM110.1 292.8H63.5v77.1h46.6v-77.1z"
        />
        <path
            fill="#FFCE6C"
            d="M44.5 195.5H0v77.1h44.5v-77.1zM130.3 272.5L181 234l-50.7-38.5h-1.2v77h1.2zM110.1 195.5H63.5v77.1h46.6v-77.1z"
        />
        <path
            fill="#A6D17A"
            d="M110.1 98.2H63.5v77.1h46.6V98.2zM251 175.2l50.8-38.5L251 98.2H129.1v77H251zM44.5 98.2H0v77.1h44.5V98.2z"
        />
        <path
            fill="#7DBAE7"
            d="M44.5.9H0V78h44.5V.9zM418.4 77.9l50.7-38.5L418.4.9H129.1v77h289.3zM110.1.9H63.5V78h46.6V.9z"
        />
    </svg>
)

export default IconBarChartGraph;
