// Packages
import {createStore} from "redux";
// Reducers
import rootReducer from "./reducers/rootReducer";


export default createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
