// Packages
import cn from "classnames";
// Styles
import scss from "./button.module.scss";


const styles = {
    block: "w-full",
    common: scss.btn,
    disabled: "disabled:opacity-60 disabled:pointer-events-none",
    icon: "relative bottom-1",
    iconWrapper: {
        bottom: "mt-6",
        left: "mr-6",
        right: "ml-6",
        top: "mb-6"
    },
    isProgress: {
        icon: "animate-spin",
        text: "ml-8",
        wrapper: "flex items-center"
    },
    size: {
        large: scss.btnLarge,
        small: scss.btnSmall
    },
    variant: {
        normal: {
            danger: cn(
                "bg-danger text-white border-danger",
                "hover:bg-dangerDarken"
            ),
            dark: cn(
                "bg-dark text-white border-dark",
                "hover:bg-darkDarken"
            ),
            default: cn(
                "bg-white text-gray-800 border-gray-200"
            ),
            info: cn(
                "bg-info text-white border-info",
                "hover:bg-infoDarken"
            ),
            light: cn(
                "bg-light text-gray-500 border-light",
                "hover:bg-lightDarken"
            ),
            orange: cn(
                "bg-orange text-white border-orange",
                "hover:bg-orangeDarken"
            ),
            primary: cn(
                "bg-primary text-white border-primary",
                "hover:bg-primaryDarken"
            ),
            secondary: cn(
                "bg-secondary text-gray-700 border-secondary",
                "hover:bg-secondaryDarken"
            ),
            success: cn(
                "bg-success text-white border-success",
                "hover:bg-successDarken"
            ),
            warning: cn(
                "bg-warning text-white border-warning",
                "hover:bg-warningDarken"
            )
        },
        outline: {
            danger: cn(
                "bg-white text-danger border-danger",
                "hover:bg-danger hover:text-white"
            ),
            dark: cn(
                "bg-white text-dark border-dark",
                "hover:bg-dark hover:text-white"
            ),
            info: cn(
                "bg-white text-info border-info",
                "hover:bg-info hover:text-white"
            ),
            light: cn(
                "bg-white text-gray-500 border-lightDarken",
                "hover:bg-lightDarken"
            ),
            orange: cn(
                "bg-white text-orange border-orange",
                "hover:bg-orange hover:text-white"
            ),
            primary: cn(
                "bg-white text-primary border-primary",
                "hover:bg-primary hover:text-white"
            ),
            secondary: cn(
                "bg-white text-gray-700 border-secondary",
                "hover:bg-secondary"
            ),
            success: cn(
                "bg-white text-success border-success",
                "hover:bg-success hover:text-white"
            ),
            warning: cn(
                "bg-white text-warning border-warning",
                "hover:bg-warning hover:text-white"
            )
        }
    },
    wrapperForIconPosition: {
        bottom: "flex-col-reverse",
        left: "",
        right: "flex-row-reverse",
        top: "flex-col"
    }
}

export default styles;
