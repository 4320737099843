// Packages
import React from "react";


const IconClose = props => (
    <svg
        fill="currentColor"
        viewBox="0 0 12 12"
        {...props}
    >
        <path
            clipRule="evenodd"
            d="M5.11598 6.00001L0.557983 10.558L1.44198 11.442L5.99998 6.88401L10.558 11.442L11.442 10.558L6.88398 6.00001L11.442 1.44201L10.558 0.558014L5.99998 5.11601L1.44198 0.558014L0.557983 1.44201L5.11598 6.00001Z"
            fillRule="evenodd"
        />
    </svg>

)

export default IconClose;
