// Packages
import React from "react";


const IconArrowDown = props => (
    <svg
        fill="currentColor"
        viewBox="0 0 12 7"
        {...props}
    >
        <path
            d="M5.575 6.55a1.03 1.03 0 01-.7-.275l-4.6-4.6A.948.948 0 010 .975c0-.283.092-.517.275-.7A.948.948 0 01.975 0c.283 0 .517.092.7.275l3.9 3.9 3.9-3.9a.948.948 0 01.7-.275c.283 0 .517.092.7.275a.948.948 0 01.275.7.948.948 0 01-.275.7l-4.6 4.6c-.1.1-.208.17-.325.212a1.099 1.099 0 01-.375.063z"
        />
    </svg>
)

export default IconArrowDown;
