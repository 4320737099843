// Packages
import React, { useEffect, useState } from "react";
import _, { toString } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import Moment from 'moment';
import { shallowEqual, useSelector } from "react-redux";

// Layouts
import MainLayout from "../../layouts/MainLayout";

// Utils
import { IconLifeGuard } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";

// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_SUPPORT_REQUEST_BY_ID } from "../../core/Constants/API_URL";

// Components
import { Accordion, Button } from "../../components";
import IconBack from "../../utils/icons/_back";

const SupportRequestDetailPage = () => {
    const user = useSelector(state => state.user, shallowEqual);
    const navigate = useNavigate();
    const { id } = useParams();
    const [dataSource, setDataSource] = useState({});

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    useEffect(() => {
        if (user.personType !== 2) {
            navigate('/');
            return;
        }
        const fetchData = async () => {
            const responseData = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_SUPPORT_REQUEST_BY_ID.replace(":id", id)
                }
            });
            
            if (!responseData.isError) {
                let cats = [];

                _.forEach(responseData.items, item => {
                    if (cats.indexOf(item.category) < 0) {
                        cats.push(item.category);
                    }
                });

                responseData["categories"] = cats;

                setDataSource(responseData);
            }
        }

        fetchData().then(p => p);
    }, [])

    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white">
                    <IconLifeGuard className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <div className="flex">
                        <div className="mr-10">
                            <Button
                                className="mt-10 text-16"
                                onClick={() => navigate("/support-requests")}
                                icon={<IconBack width={12} />}
                                variant="default"
                            />
                        </div>
                        <div>
                            <h1 className="text-24 font-bold text-gray-800 relative z-1">
                                #DI{toString(dataSource.id).padStart(5,'0')}
                            </h1>
                            <h6 className="text-12 text-gray-800">{Moment(dataSource.cDate).format('DD.MM.YYYY HH:mm')}</h6>
                        </div>
                    </div>
                </div>

                <div className="p-16 text-14 bg-white">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td width={200} className="px-8 py-4"><b>Ad Soyad</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.name}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Kurum/Klinik Adı</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.corporationName}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Telefon</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.gsm}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>İl/İlçe</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.city}/{dataSource.town}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Adres</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.address}</td>
                            </tr>
                            <tr>
                                <td width={150} className="px-8 py-4"><b>Açıklama</b></td>
                                <td width={10} className="px-8 py-4">:</td>
                                <td className="px-8 py-4">{dataSource.description}</td>
                            </tr>
                        </tbody>
                    </table>



                    {
                        dataSource.categories && dataSource.categories.length > 0 ?
                            <>

                                <h1 className="px-8 py-4 text-14 font-bold mt-20">
                                    İhtiyaçlar
                                </h1>
                                <hr />

                                <Accordion
                                    items={_.map(dataSource.categories, (item, _index) => {
                                        return {
                                            body: (
                                                <>
                                                    <table cellPadding={4} className="table-with-text">
                                                        <thead>
                                                            <tr className="bg-gray-100">
                                                                <th width={30}>#</th>
                                                                <th>İhtiyaç</th>
                                                                <th width={120}>{item.indexOf("Hekim") >= 0 ? "Sayı" : "İhtiyaç Miktarı"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                _.map(_.filter(dataSource.items, i => i.category === item), (sItem, sIndex) => {
                                                                    return (
                                                                        <tr key={`table_row_${sIndex}`}>
                                                                            <td data-title="#">{sIndex + 1}</td>
                                                                            <td data-title="İhtiyaç">
                                                                                {sItem.name}
                                                                            </td>
                                                                            <td data-title={item.indexOf("Hekim") >= 0 ? "Sayı" : "Miktar"}>
                                                                                {sItem.quantity} {sItem.unit}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>

                                                </>
                                            ),
                                            expand: true,
                                            header: item,
                                            ...item
                                        }
                                    })
                                    }
                                />
                            </>
                            : <>
                            </>
                    }




                    <div className="flex justify-between mt-16">
                        <Button
                            icon={<IconBack width={12} />}
                            className="flex-6"
                            onClick={() => navigate("/support-requests")}
                            text="Listeye Dön"
                            variant="default"
                        />
                    </div>
                </div>
            </div>
        </MainLayout >
    );
}

export default SupportRequestDetailPage;
