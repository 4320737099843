const styles = {
    input: {
        checked: "border-blue-500 bg-blue-500",
        common: "border-1 flex items-center justify-center cursor-pointer",
        size: {
            default: "w-24 h-24 min-w-24 min-h-24",
            small: "w-18 h-18"
        },
        unchecked: "border-gray-300 bg-white"
    },
    label: "ml-5",
    wrapper: "flex items-center"
}

export default styles;
